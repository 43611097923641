import {
  PropertyValue,
  PropertyValueSet,
  PropertyFilter
} from "@genesys/property";
import { Quantity } from "@genesys/uom";
import * as PropertyFilterHelpers from "../property-filter-helpers";

interface Property {
  readonly name: string;
  readonly quantity: string;
  readonly defaultValues: ReadonlyArray<DefaultValue>;
  readonly values: ReadonlyArray<PropertyValueItem>;
}

interface PropertyValueItem {
  readonly value: string;
}

type DefaultValue = {
  readonly value: PropertyValue.PropertyValue;
  readonly propertyFilter: PropertyFilter.PropertyFilter;
};

export function autoSelectSingleValidValue(
  productProperties: ReadonlyArray<{
    readonly validationFilter: string;
    readonly quantity: string;
    readonly name: string;
    readonly values: ReadonlyArray<{
      readonly validationFilter: string;
      readonly value: string;
    }>;
  }>,
  properties: PropertyValueSet.PropertyValueSet,
  changedPropertyName: string
): PropertyValueSet.PropertyValueSet {
  let lastProperties = properties;

  const discretePropertiesWithValidationFilter = productProperties.filter(
    x => x.quantity === "Discrete" && x.validationFilter !== undefined
  );

  for (let i = 0; i < 4; i++) {
    for (const productPropertyWithValidationFilter of discretePropertiesWithValidationFilter) {
      const { name, values } = productPropertyWithValidationFilter;
      if (name === changedPropertyName || name.startsWith("range")) {
        continue;
      }
      const propertyValueItem = getSingleValidValueOrUndefined(
        productPropertyWithValidationFilter,
        properties
      );
      if (propertyValueItem) {
        properties = PropertyValueSet.set(
          name,
          PropertyValue.fromInteger(parseInt(propertyValueItem.value, 10)),
          properties
        );
      }

      const selectedValueItem =
        PropertyValueSet.hasProperty(name, properties) &&
        values.find(
          v =>
            v.value ===
            PropertyValue.toString(PropertyValueSet.get(name, properties)!)
        );
      if (
        selectedValueItem &&
        !PropertyFilterHelpers.isValid(
          selectedValueItem.validationFilter,
          PropertyFilter.Empty,
          properties
        )
      ) {
        const firstValid = values.find(v =>
          PropertyFilterHelpers.isValid(
            v.validationFilter,
            PropertyFilter.Empty,
            properties
          )
        );
        if (firstValid) {
          properties = PropertyValueSet.set(
            name,
            PropertyValue.fromInteger(parseInt(firstValid.value, 10)),
            properties
          );
        }
      }
    }

    if (properties === lastProperties) {
      break;
    }

    lastProperties = properties;
  }

  return lastProperties;
}

export function createDefaultProperties(
  properties: ReadonlyArray<Property>,
  selectFirstValue: boolean = false
): PropertyValueSet.PropertyValueSet {
  const createDefaultProperty = createDefaultPropertyCreator(selectFirstValue);
  const resultSet1 = properties.reduce(createDefaultProperty, {});
  const resultSet2 = properties.reduce(createDefaultProperty, resultSet1);
  const resultSet3 = properties.reduce(createDefaultProperty, resultSet2);

  return resultSet3;
}

interface MutablePropertyValueSet {
  // tslint:disable-next-line:readonly-keyword
  [key: string]: PropertyValue.PropertyValue;
}

function createDefaultPropertyCreator(selectFirstValue: boolean = false) {
  return (
    mutablePropertyValueSet: MutablePropertyValueSet,
    property: Property
  ): PropertyValueSet.PropertyValueSet => {
    const quantity = property.quantity as Quantity.Quantity;

    const defaultValue = property.defaultValues.find(d =>
      PropertyFilter.isValid(mutablePropertyValueSet, d.propertyFilter)
    )?.value;

    if (defaultValue !== undefined) {
      setProperty(mutablePropertyValueSet, property.name, defaultValue);

      return mutablePropertyValueSet;
    }

    if (selectFirstValue && property.values && property.values.length > 0) {
      setProperty(
        mutablePropertyValueSet,
        property.name,
        createPropertyValue(quantity, property.values[0].value)
      );
      return mutablePropertyValueSet;
    }

    return mutablePropertyValueSet;
  };
}

function setProperty(
  mutablePropertyValueSet: MutablePropertyValueSet,
  name: string,
  propertyValue: PropertyValue.PropertyValue
): void {
  mutablePropertyValueSet[name] = propertyValue;
}

function createPropertyValue(
  quantity: Quantity.Quantity | string,
  value: string
): PropertyValue.PropertyValue {
  if (quantity === "Text") {
    return PropertyValue.fromText(value);
  }

  return PropertyValue.fromString(value)!;
}

function getSingleValidValueOrUndefined(
  productProperty: {
    readonly quantity: string;
    readonly values: ReadonlyArray<{
      readonly validationFilter: string;
      readonly value: string;
    }>;
  },
  properties: PropertyValueSet.PropertyValueSet
): { readonly value: string } | undefined {
  const quantity = productProperty.quantity as Quantity.Quantity;
  if (quantity === "Discrete") {
    const validPropertyValueItems: Array<{
      readonly value: string;
    }> = [];
    for (const productValueItem of productProperty.values) {
      const isValid = PropertyFilterHelpers.isValid(
        productValueItem.validationFilter,
        PropertyFilter.Empty,
        properties
      );

      if (isValid) {
        validPropertyValueItems.push(productValueItem);
      }
    }

    return validPropertyValueItems.length === 1
      ? validPropertyValueItems[0]
      : undefined;
  }

  return undefined;
}
