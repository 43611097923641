import {
  PropertyValueSet,
  PropertyFilter,
  PropertyValue
} from "@genesys/property";
import { ErrorSeverity } from "@genesys/primitives";
import * as PropertyFilterHelpers from "../property-filter-helpers";
import * as LanguageTexts from "../language-texts";
import {
  ComponentMessage,
  RootMessage,
  MessagePart,
  MessagePartAmount,
  MessagePartText,
  OperatingCaseResult,
  RootComponentMessage,
  RootGroupedMessage,
  RootMessageEntry
} from "./types";
import { Amount, Quantity } from "@genesys/uom";
import { exhaustiveCheck } from "ts-exhaustive-check";
import { ComponentCalculationType } from "@genesys/graphql-types";

type HelpTextRows = ReadonlyArray<{
  readonly propertyFilter?: string | null | undefined;
  readonly values: string;
}>;
export function getRootMessages(
  translate: LanguageTexts.Translate,
  products: ReadonlyArray<{
    readonly id: string;
    readonly helpTextTable:
      | {
          readonly rows: HelpTextRows;
        }
      | null
      | undefined;
  }>,
  systemType: string,
  componentMessages: ReadonlyArray<ComponentMessage>,
  operatingCaseResults: ReadonlyArray<OperatingCaseResult>
): RootMessage {
  const keyValue: ReadonlyArray<[string, HelpTextRows]> = products.map(p => {
    return [p.id, p.helpTextTable?.rows || []] as any;
  });
  const productHelpTextMap = new Map<string, HelpTextRows>(keyValue);

  const createMessages = ({
    exception,
    error,
    warning,
    information
  }: {
    readonly exception: ReadonlyArray<RootComponentMessage>;
    readonly error: ReadonlyArray<RootComponentMessage>;
    readonly warning: ReadonlyArray<RootComponentMessage>;
    readonly information: ReadonlyArray<RootComponentMessage>;
  }): RootMessage => ({
    exception: {
      image: getImage(ErrorSeverity.Exception),
      componentMessages: exception
    },
    error: {
      image: getImage(ErrorSeverity.Error),
      componentMessages: error
    },
    warning: {
      image: getImage(ErrorSeverity.Warning),
      componentMessages: warning
    },
    information: {
      image: getImage(ErrorSeverity.Info),
      componentMessages: information
    }
  });
  const createComponentMessageFactory = (
    severity: ErrorSeverity,
    group: boolean
  ): ReadonlyArray<RootComponentMessage> =>
    createComponentMessage(
      componentMessages.filter(cm => cm.severity === severity),
      translate,
      operatingCaseResults,
      productHelpTextMap,
      systemType,
      group
    );

  if (componentMessages.some(cm => cm.severity === ErrorSeverity.Exception)) {
    return createMessages({
      exception: createComponentMessageFactory(ErrorSeverity.Exception, true),
      error: [],
      warning: [],
      information: []
    });
  }
  if (componentMessages.some(cm => cm.severity === ErrorSeverity.Error)) {
    return createMessages({
      exception: [],
      error: createComponentMessageFactory(ErrorSeverity.Error, true),
      warning: [],
      information: []
    });
  }
  return createMessages({
    exception: [],
    error: [],
    warning: createComponentMessageFactory(ErrorSeverity.Warning, true),
    information: createComponentMessageFactory(ErrorSeverity.Info, false)
  });
}

function getMessageParts(
  translate: LanguageTexts.Translate,
  systemType: string,
  componentMessage: ComponentMessage,
  extendedErrorTextId: string | undefined,
  generic: boolean
): ReadonlyArray<MessagePart> {
  const message = extendedErrorTextId
    ? getExtendedErrorText(
        translate,
        systemType,
        extendedErrorTextId,
        componentMessage.code
      )
    : getDefaultErrorText(translate, systemType, componentMessage.code);

  return [
    getMessageHeading(translate, componentMessage.parameters),
    ...getMessageBody(
      translate,
      componentMessage.parameters,
      componentMessage.code,
      message,
      systemType,
      generic
    )
  ];
}

function getHeading(
  translate: LanguageTexts.Translate,
  operatingCaseResult: OperatingCaseResult | undefined
): string {
  if (!operatingCaseResult) {
    return "";
  }
  if (
    operatingCaseResult.calculationType ===
      ComponentCalculationType.SELECTION ||
    operatingCaseResult.calculationType === ComponentCalculationType.MAX_RESULT
  ) {
    return translate(LanguageTexts.selection());
  }
  if (operatingCaseResult.operatingCase.caseName === "Custom") {
    return operatingCaseResult.operatingCase.customCaseName ?? "";
  }
  return translate(
    LanguageTexts.operatingCaseName(operatingCaseResult.operatingCase.caseName)
  );
}

// This is only for old systems
function getMessageHeading(
  translate: LanguageTexts.Translate,
  parameters: PropertyValueSet.PropertyValueSet
): MessagePartText {
  const casename = PropertyValueSet.getText("casename", parameters);
  if (casename === undefined) {
    return {
      kind: "MessagePartText",
      text: ""
    };
  }
  const heading =
    casename === "Custom"
      ? `${PropertyValueSet.getText("customcasename", parameters)}: `
      : `${translate(LanguageTexts.operatingCaseName(casename))}: `;

  return {
    kind: "MessagePartText",
    text: heading
  };
}

function getMessageBody(
  translate: LanguageTexts.Translate,
  parameters: PropertyValueSet.PropertyValueSet,
  errorCode: number,
  message: string,
  systemType: string,
  generic: boolean
): ReadonlyArray<MessagePart> {
  const textId = PropertyValueSet.getText("textid", parameters);
  if (textId) {
    return [
      {
        kind: "MessagePartText" as const,
        text: translate(LanguageTexts.dynamicText(textId, systemType))
      },
      {
        kind: "MessagePartText",
        text: `(${errorCode})`
      }
    ];
  }
  const parts: Array<MessagePart> = message
    .split(" ")
    .reduce((soFar: Array<MessagePart>, word) => {
      // Splits by {propertName} and includes the match in the split array
      const splitted = word.split(/(\{.+?\})/);

      for (const wordPart of splitted) {
        if (word.indexOf("{") === -1) {
          const messagePart: MessagePartText = {
            kind: "MessagePartText",
            text: wordPart
          };
          soFar.push(messagePart);
          return soFar;
        }

        const wordPartWithoutBracketAndLower = wordPart
          .replace(/[\{\}]/g, "")
          .toLowerCase();
        const propertyValue = PropertyValueSet.get(
          wordPartWithoutBracketAndLower,
          PropertyValueSet.map(
            kvp => ({ key: kvp.key.toLowerCase(), value: kvp.value }),
            parameters
          )
        );

        if (propertyValue === undefined) {
          const messagePart: MessagePartText = {
            kind: "MessagePartText",
            text: wordPart
          };
          soFar.push(messagePart);
          continue;
        }

        if (generic) {
          const messagePart: MessagePartText = {
            kind: "MessagePartText",
            text: wordPartWithoutBracketAndLower
          };
          soFar.push(messagePart);
          continue;
        }

        if (propertyValue.type === "amount") {
          const messagePart: MessagePartAmount = {
            kind: "MessagePartAmount",
            amount: PropertyValue.getAmount(
              propertyValue
            )! as Amount.Amount<Quantity.Quantity>,
            propertyName: wordPartWithoutBracketAndLower
          };
          soFar.push(messagePart);
          continue;
        }

        // Get text and match textids
        let text = PropertyValue.getText(propertyValue)!;
        const textIdRegex = new RegExp(/({[A-Za-z0-9_]+})/gi);
        const matches = text.match(textIdRegex);

        if (matches !== null) {
          text = matches.reduce((soFar, m) => {
            const translation = translate(
              LanguageTexts.dynamicText(
                m.replace("{", "").replace("}", ""),
                systemType
              )
            );
            soFar = soFar.replace(m, translation);
            return soFar;
          }, text);
        }

        const messagePart: MessagePartText = {
          kind: "MessagePartText",
          text: text
        };
        soFar.push(messagePart);
        continue;
      }

      return soFar;
    }, []);

  return [
    ...parts,
    {
      kind: "MessagePartText",
      text: `(${errorCode})`
    }
  ];
}

function getExtendedErrorText(
  translate: LanguageTexts.Translate,
  systemType: string,
  extendedErrorTextId: string,
  errorCode: number
): string {
  const translateKey = `ErrorCode_${errorCode}_${extendedErrorTextId}`;
  return translate(LanguageTexts.dynamicText(translateKey, systemType));
}

function getDefaultErrorText(
  translate: LanguageTexts.Translate,
  systemType: string,
  errorCode: number
): string {
  const translateKey = `ErrorCode_${systemType}_${errorCode}`;
  const translated = translate(
    LanguageTexts.dynamicText(translateKey, systemType)
  );
  const wasMissing = translated === `{${translateKey}}`;

  if (!wasMissing) {
    return translated;
  }

  return getFallbackText(translate, errorCode);
}

function getFallbackText(
  translate: LanguageTexts.Translate,
  errorCode: number
): string {
  return translate(LanguageTexts.dynamicText(`ErrorCode_${errorCode}`));
}

function getExtendedErrorTextId(
  productHelpTextMap: Map<string, HelpTextRows>,
  productId: string,
  properties: PropertyValueSet.PropertyValueSet,
  errorCode: number
): string | undefined {
  const helpTextRowVaues = (productHelpTextMap.get(productId) || [])
    .filter(r =>
      PropertyFilterHelpers.isValid(
        r.propertyFilter,
        PropertyFilter.Empty,
        properties
      )
    )
    .map(r =>
      PropertyValueSet.fromStringOrError(() => PropertyValueSet.Empty, r.values)
    );
  if (helpTextRowVaues.length === 0) {
    return undefined;
  }

  const errorCodeString = errorCode.toString();
  const helpTextRow = helpTextRowVaues.find(ht => {
    return PropertyValueSet.getText("ErrorCode", ht) === errorCodeString;
  });

  return (
    (helpTextRow && PropertyValueSet.getText("TextId", helpTextRow)) ||
    undefined
  );
}

function getImage(errorSeverity: ErrorSeverity): string {
  switch (errorSeverity) {
    case ErrorSeverity.Exception:
      return "error-exception.png";
    case ErrorSeverity.Error:
      return "error.png";
    case ErrorSeverity.Warning:
    case ErrorSeverity.CriticalWarning:
      return "warning.png";
    case ErrorSeverity.Info:
      return "information.png";
    default:
      return exhaustiveCheck(errorSeverity);
  }
}

function createComponentMessage(
  messages: ReadonlyArray<ComponentMessage>,
  translate: LanguageTexts.Translate,
  operatingCaseResults: ReadonlyArray<OperatingCaseResult>,
  productHelpTextMap: Map<string, HelpTextRows>,
  systemType: string,
  group: boolean
): ReadonlyArray<RootComponentMessage> {
  const componentMessageMap = new Map<
    string,
    ReadonlyArray<ComponentMessage>
  >();
  for (const msg of messages) {
    const key = msg.componentId;
    const value = componentMessageMap.get(key);
    if (value) {
      componentMessageMap.set(key, [...value, msg]);
    } else {
      componentMessageMap.set(key, [msg]);
    }
  }

  return [...componentMessageMap.entries()].map(([key, value]) => {
    const groups = groupMessageCode(
      value,
      translate,
      operatingCaseResults,
      productHelpTextMap,
      systemType,
      group
    );
    return {
      componentId: key,
      productName: LanguageTexts.getComponentLabel(
        translate,
        value[0].productId,
        value[0].properties
      ),
      groups: groups
    };
  });
}

function groupMessageCode(
  messages: ReadonlyArray<ComponentMessage>,
  translate: LanguageTexts.Translate,
  operatingCaseResults: ReadonlyArray<OperatingCaseResult>,
  productHelpTextMap: Map<string, HelpTextRows>,
  systemType: string,
  group: boolean
): ReadonlyArray<RootGroupedMessage> {
  const map = new Map<string, ReadonlyArray<ComponentMessage>>();
  for (const msg of messages) {
    const key = msg.code.toString() + (group ? "" : `_${msg.id}`);
    const value = map.get(key);
    if (value) {
      map.set(key, [...value, msg]);
    } else {
      map.set(key, [msg]);
    }
  }

  return [...map.entries()].map(([_key, value]) => {
    const values = value.map(v => {
      const operatingCaseResult = operatingCaseResults.find(
        r => r.id === v.operatingCaseResultId
      );
      return {
        operatingCaseResult: operatingCaseResult,
        entries: messageEntry(
          v,
          translate,
          operatingCaseResult,
          productHelpTextMap,
          systemType
        )
      };
    });

    const sortedEntries = [...values]
      .sort((a, b) =>
        sortOperatingCaseResults(a.operatingCaseResult, b.operatingCaseResult)
      )
      .map(v => v.entries);

    const extendedErrorTextId = getExtendedErrorTextId(
      productHelpTextMap,
      value[0].productId,
      value[0].properties,
      value[0].code
    );

    return {
      code: value[0].code,
      entries: sortedEntries,
      genericParts: getMessageParts(
        translate,
        systemType,
        value[0],
        extendedErrorTextId,
        true
      )
    };
  });
}

function messageEntry(
  message: ComponentMessage,
  translate: LanguageTexts.Translate,
  operatingCaseResult: OperatingCaseResult | undefined,
  productHelpTextMap: Map<string, HelpTextRows>,
  systemType: string
): RootMessageEntry {
  const extendedErrorTextId = getExtendedErrorTextId(
    productHelpTextMap,
    message.productId,
    message.properties,
    message.code
  );
  return {
    id: message.id,
    heading: getHeading(translate, operatingCaseResult),
    parts: getMessageParts(
      translate,
      systemType,
      message,
      extendedErrorTextId,
      false
    ),
    operatingCaseResultId: message.operatingCaseResultId
  };
}

function sortOperatingCaseResults(
  a: OperatingCaseResult | undefined,
  b: OperatingCaseResult | undefined
): number {
  if (!a) {
    return -1;
  }
  if (!b) {
    return 1;
  }
  if (!a && !b) {
    return 0;
  }
  if (a.operatingCase.sortNo < b.operatingCase.sortNo) {
    return -1;
  }
  if (a.operatingCase.sortNo > b.operatingCase.sortNo) {
    return 1;
  }
  return 0;
}
//tslint:disable-next-line
