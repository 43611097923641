import * as uuid from "uuid";
import {
  PropertyFilter,
  PropertyValueSet,
  PropertyValue
} from "@genesys/property";
import { Quantity } from "@genesys/uom";
import * as Calculations from "@munters/calculations";
import { ErrorSeverity } from "@genesys/primitives";
import * as Types from "./types";
import * as Helper from "./helper";
import { createFlowDiagramPort } from "./create-flowdiagram-port";

export function buildNodesAndEdges(
  products: ReadonlyArray<Types.Product>,
  system: Types.System,
  showErrors: boolean,
  diagramType: Types.DiagramType,
  includeLabel: boolean,
  translatePropertyValue: (
    productId: string,
    propertyName: string,
    propertyValue: number
  ) => string,
  translateText: (key: string) => string,
  curlyTranslate: (key: string) => string,
  getAmountFormatString: (
    fieldGroup: string,
    fieldName: string,
    quantity: Quantity.Quantity,
    property: PropertyValue.AmountPropertyValue
  ) => string
): Types.Diagram {
  const nodes = Array<Types.Node>();

  for (const component of system.components) {
    const product = products.find(p => p.id === component.productId);
    if (!product) {
      continue;
    }

    const connectionPoints = product.boxConnectionPoints
      .filter(
        cp =>
          cp.diagramType === diagramType &&
          PropertyFilter.isValid(component.properties, cp.propertyFilter)
      )
      .sort((a, b) => (a.positionX > b.positionX ? -1 : 1)); // sorting is done because the rendering can differ depending on the order of the connection points.

    const symbols = product.boxSymbols.filter(
      s =>
        s.diagramType === diagramType &&
        PropertyFilter.isValid(component.properties, s.propertyFilter)
    );
    const texts = product.boxTexts.filter(
      t =>
        t.diagramType === diagramType &&
        PropertyFilter.isValid(component.properties, t.propertyFilter)
    );
    const ioPoints = product.boxLinePoints.filter(
      p =>
        p.diagramType === diagramType &&
        PropertyFilter.isValid(component.properties, p.propertyFilter)
    );

    const boxes = new Set([
      ...connectionPoints.map(cp => cp.box),
      ...symbols.map(s => s.box),
      ...texts.map(t => t.box),
      ...ioPoints.map(io => io.box)
    ]);

    for (const box of boxes) {
      const boxSymbols = Array<Types.Symbol>();

      for (const s of symbols.filter(s => s.box === box)) {
        const symbolTransform = Calculations.Math.Matrix2.composite(
          s.rotation,
          s.mirrorX,
          s.mirrorY,
          s.positionX,
          s.positionY
        );
        boxSymbols.push({
          id: s.id,
          transform: symbolTransform,
          size: Calculations.Math.Vector2.vec2Create(s.sizeX, s.sizeY),
          z: s.positionZ,
          symbolId: s.symbolId,
          clickable: s.useForSelectionBounds,
          noRotation: s.absoluteRotationAndScale
        });
      }

      if (showErrors) {
        const highestErrorSeverity = component.messages.reduce(
          (a, b) => Math.max(a, b.messageSeverity),
          -1
        );

        const symbolName = getShowErrorSymbolName(highestErrorSeverity);
        if (symbolName !== "None") {
          boxSymbols.push({
            id: "",
            transform: Calculations.Math.Matrix2.identity,
            size: Calculations.Math.Vector2.vec2Create(15, 15),
            z: 1000,
            symbolId: symbolName,
            clickable: false,
            noRotation: true
          });
        }
      }

      const boxPorts = Array<Types.Port>();
      for (const p of connectionPoints.filter(c => c.box === box)) {
        const componentSection = component.sections.find(
          cs => cs.productSectionId === p.productSectionId
        );
        if (!componentSection) {
          continue;
        }

        boxPorts.push(createFlowDiagramPort(componentSection.id, p));
      }

      const boxTexts = Array<Types.Text>();

      for (const text of texts.filter(
        t => !t.useAsLabel || (includeLabel && t.useAsLabel)
      )) {
        let translatedString = "";

        switch (text.source) {
          case "FreeText": {
            translatedString = text.text;
            break;
          }
          case "TextId": {
            translatedString = translateText(text.text);
            break;
          }
          case "Property": {
            const property = PropertyValueSet.get(
              text.text,
              component.properties
            );

            if (property === undefined) {
              translatedString = `No property with name ${text.text}`;
              break;
            }

            switch (property.type) {
              case "text":
                translatedString = PropertyValue.getText(property)!;
                break;
              case "integer":
                translatedString = translatePropertyValue(
                  component.productId,
                  text.text,
                  PropertyValue.getInteger(property)!
                );
                break;
              case "amount":
                const propertyDef = product.properties.find(
                  p => p.name === text.text
                )!;
                translatedString = getAmountFormatString(
                  "Settings." + component.productId,
                  text.text,
                  propertyDef.quantity,
                  property
                );
                break;
              default:
                break;
            }
            break;
          }
          case "Specification": {
            translatedString = "Not Implemented";
            break;
          }
          default: {
            translatedString = text.text;
          }
        }

        boxTexts.push({
          position: Calculations.Math.Vector2.vec2Create(
            text.positionX,
            text.positionY
          ),
          rotation: text.rotation,
          absolutePosition: text.absolutePosition,
          fontSize: text.fontSize,
          z: text.positionZ,
          text: translatedString
        });
      }

      const boxIoPoints = Array<Types.LinePoint>();
      for (const p of ioPoints.filter(io => io.box === box)) {
        boxIoPoints.push({
          connectionId: p.connectionId,
          direction: p.direction as Types.Direction,
          lineType: p.lineType as Types.LineType,
          transform: Calculations.Math.Matrix2.translate(
            p.positionX,
            p.positionY
          )
        });
      }
      const label = component.label
        ? translateText(
            component.productId.substr(0, 3) + "_" + component.label
          )
        : undefined;

      nodes.push({
        id: uuid.v4(),
        subGraphId: "",
        componentId: component.id,
        componentLabel: getComponentLabel(
          component.properties,
          component.productId,
          curlyTranslate,
          translateText
        ),
        box: box,
        productId: component.productId,
        label: label,
        transform: Calculations.Math.Matrix2.identity,
        velocity: Calculations.Math.Vector2.vec2Zero,
        ports: boxPorts,
        symbols: boxSymbols,
        texts: boxTexts,
        ioPoints: boxIoPoints
      });
    }
  }

  const edges = Array<Types.Edge>();
  for (const stream of system.airstreams) {
    for (let ix = 0; ix < stream.componentSections.length; ix++) {
      if (!ix) {
        continue;
      }

      const section = stream.componentSections[ix];
      const previous = stream.componentSections[ix - 1];

      const component1 = system.components.find(
        c => !!c.sections.find(cs => cs.id === previous.id)
      )!;
      const component1Nodes = nodes.filter(
        n =>
          !!n.ports.find(
            p =>
              p.connectionType === "Outlet" &&
              p.componentSectionId === previous.id
          )
      );
      const component2 = system.components.find(
        c => !!c.sections.find(cs => cs.id === section.id)
      )!;
      const component2Nodes = nodes.filter(
        n =>
          !!n.ports.find(
            p =>
              p.connectionType === "Inlet" &&
              p.componentSectionId === section.id
          )
      );

      if (component1Nodes.length === 1 && component2Nodes.length === 1) {
        edges.push({
          node1: component1.id,
          node1Box: component1Nodes[0].box,
          node1Section: previous.id,
          node2: component2.id,
          node2Box: component2Nodes[0].box,
          node2Section: section.id,
          length: Helper.edgeLength
        });
      }
    }
  }

  return {
    type: diagramType,
    nodes: nodes,
    edges: edges,
    lines: []
  };
}

function getComponentLabel(
  properties: PropertyValueSet.PropertyValueSet,
  productId: string,
  curlyTranslate: (key: string, textDb: string) => string,
  translateText: (key: string) => string
): string {
  const maybeLabel = PropertyValueSet.getText("label", properties);
  return maybeLabel
    ? curlyTranslate(maybeLabel, productId.substring(0, 3))
    : translateText(productId);
}

type ErrorSymbol = "Exception" | "Error" | "Warning" | "Information" | "None";

function getShowErrorSymbolName(errorSeverity: number): ErrorSymbol {
  switch (errorSeverity) {
    case ErrorSeverity.Exception:
      return "Exception";
    case ErrorSeverity.Error:
      return "Error";
    case ErrorSeverity.Warning:
    case ErrorSeverity.CriticalWarning:
      return "Warning";
    case ErrorSeverity.Info:
      return "Information";
    default:
      return "None";
  }
}
