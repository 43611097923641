import * as React from "react";
import { exhaustiveCheck } from "ts-exhaustive-check";
import { ErrorSeverity } from "@genesys/primitives";
import { AlertSlider } from "@genesys/ui-elements";
import * as ComponentsMessages from "@genesys/shared/lib/components-messages";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as ScreenAmounts from "@genesys/shared/lib/screen-amounts";
import * as Product from "../product";
import { AlertContainer } from "../elements";
import * as SharedState from "../../shared-state";
import styled from "styled-components";
import { PrimaryColors } from "@genesys/ui-elements";
import { MessageSingle } from "./messages/message-single";
import { MessageGroup } from "./messages/message-group";
import { AlertMessageBoxHeader, AlertMessageBox } from "./messages/elements";

const StyledDiv = styled.div`
  width: 100%;
  border-top: 1px solid black;
`;

const HeaderContainer = styled.div<{ readonly color: string }>`
  box-sizing: border-box;
  width: 310px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;

  label {
    color: ${props => props.color};
    font-family: Futura;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 3px;
    line-height: 20px;
    display: block;
  }
`;

type MessageType = "warning" | "error" | "info";

export function Alerts({
  onComponentMessageHover,
  onComponentMessageClick,
  toggle,
  expanded,
  products,
  systemTypeId,
  componentsMessages,
  operatingCaseResults,
  expandedGroups,
  collapseGroupException,
  expandGroupException,
  expandGroupError,
  collapseGroupError,
  collapseGroupWarning,
  expandGroupWarning,
  sharedState
}: {
  readonly onComponentMessageHover: (componentId: string | undefined) => void;
  readonly onComponentMessageClick: (componentId: string) => void;
  readonly toggle: () => void;
  readonly expanded: boolean;
  readonly products: ReadonlyArray<Product.Product>;
  readonly componentsMessages: ReadonlyArray<ComponentsMessages.ComponentMessage>;
  readonly operatingCaseResults: ReadonlyArray<ComponentsMessages.OperatingCaseResult>;
  readonly systemTypeId: string;
  readonly expandedGroups: {
    readonly exceptions: ReadonlySet<string>;
    readonly errors: ReadonlySet<string>;
    readonly warnings: ReadonlySet<string>;
  };
  readonly expandGroupException: (key: string) => void;
  readonly collapseGroupException: (key: string) => void;
  readonly expandGroupError: (key: string) => void;
  readonly collapseGroupError: (key: string) => void;
  readonly expandGroupWarning: (key: string) => void;
  readonly collapseGroupWarning: (key: string) => void;
  readonly sharedState: SharedState.State;
}) {
  const translate = sharedState.translate;

  const getAmountFormat = sharedState.screenAmounts.getAmountFormat;

  if (componentsMessages.length > 0) {
    const rootMessage = ComponentsMessages.getRootMessages(
      translate,
      products,
      systemTypeId,
      componentsMessages,
      operatingCaseResults
    );

    const countMessages = (
      messages: ReadonlyArray<ComponentsMessages.RootComponentMessage>
    ): number =>
      messages.reduce(
        (a, b) => a + b.groups.reduce((a2, b2) => a2 + b2.entries.length, 0),
        0
      );

    return (
      <AlertContainer expanded={expanded}>
        <AlertSlider
          expanded={expanded}
          toggle={toggle}
          noOfErrors={countMessages([
            ...rootMessage.exception.componentMessages,
            ...rootMessage.error.componentMessages
          ])}
          noOfInfo={countMessages(rootMessage.information.componentMessages)}
          noOfWarnings={countMessages(rootMessage.warning.componentMessages)}
        >
          <>
            <SliderContent
              translate={translate}
              message={rootMessage.exception}
              severity={ErrorSeverity.Exception}
              getAmountFormat={getAmountFormat}
              onComponentMessageHover={onComponentMessageHover}
              onComponentMessageClick={onComponentMessageClick}
              expandedGroups={expandedGroups.exceptions}
              expandGroup={expandGroupException}
              collapseGroup={collapseGroupException}
            />
            <SliderContent
              translate={translate}
              message={rootMessage.error}
              severity={ErrorSeverity.Error}
              getAmountFormat={getAmountFormat}
              onComponentMessageHover={onComponentMessageHover}
              onComponentMessageClick={onComponentMessageClick}
              expandedGroups={expandedGroups.errors}
              expandGroup={expandGroupError}
              collapseGroup={collapseGroupError}
            />
            <SliderContent
              translate={translate}
              message={rootMessage.warning}
              severity={ErrorSeverity.Warning}
              getAmountFormat={getAmountFormat}
              onComponentMessageHover={onComponentMessageHover}
              onComponentMessageClick={onComponentMessageClick}
              expandedGroups={expandedGroups.warnings}
              expandGroup={expandGroupWarning}
              collapseGroup={collapseGroupWarning}
            />
            <SliderContent
              translate={translate}
              message={rootMessage.information}
              severity={ErrorSeverity.Info}
              getAmountFormat={getAmountFormat}
              onComponentMessageHover={onComponentMessageHover}
              onComponentMessageClick={onComponentMessageClick}
              expandedGroups={new Set()}
              expandGroup={() => ({})}
              collapseGroup={() => ({})}
            />
          </>
        </AlertSlider>
      </AlertContainer>
    );
  } else {
    return <></>;
  }
}

function getMessageType(errorSeverity: ErrorSeverity): MessageType {
  switch (errorSeverity) {
    case ErrorSeverity.Exception:
    case ErrorSeverity.Error:
      return "error";
    case ErrorSeverity.Warning:
    case ErrorSeverity.CriticalWarning:
      return "warning";
    case ErrorSeverity.Info:
      return "info";
    default:
      return exhaustiveCheck(errorSeverity);
  }
}

function getColor(severity: ErrorSeverity): string {
  switch (severity) {
    case ErrorSeverity.Error:
    case ErrorSeverity.Exception:
      return PrimaryColors.red;
    case ErrorSeverity.CriticalWarning:
    case ErrorSeverity.Warning:
      return PrimaryColors.gold;
    case ErrorSeverity.Info:
      return "#2487F5";
    default:
      return exhaustiveCheck(severity);
  }
}

function SliderContent({
  message,
  getAmountFormat,
  severity,
  translate,
  onComponentMessageClick,
  onComponentMessageHover,
  expandedGroups,
  expandGroup,
  collapseGroup
}: {
  readonly message: ComponentsMessages.RootErrorMessage;
  readonly severity: ErrorSeverity;
  readonly translate: LanguageTexts.Translate;
  readonly getAmountFormat: ScreenAmounts.GetAmountFormat;
  readonly onComponentMessageHover: (componentId: string | undefined) => void;
  readonly onComponentMessageClick: (componentId: string) => void;
  readonly expandedGroups: ReadonlySet<string>;
  readonly expandGroup: (key: string) => void;
  readonly collapseGroup: (key: string) => void;
}): JSX.Element {
  if (message.componentMessages.length === 0) {
    return <></>;
  }
  return (
    <>
      <StyledDiv>
        <HeaderContainer color={getColor(severity)}>
          <label>
            {translate(
              LanguageTexts.globalPropertyName(
                getMessageType(severity).toLowerCase()
              )
            ).toUpperCase()}
          </label>
        </HeaderContainer>
        {message.componentMessages.map((m, ix) => (
          <AlertMessageBox
            key={ix}
            onClick={() => onComponentMessageClick(m.componentId)}
            onMouseEnter={() => onComponentMessageHover(m.componentId)}
            onMouseLeave={() => onComponentMessageHover(undefined)}
          >
            <AlertMessageBoxHeader>{m.productName}</AlertMessageBoxHeader>
            {m.groups.map((g, ix) =>
              g.entries.length > 1 ? (
                <MessageGroup
                  key={g.code}
                  messages={g}
                  getAmountFormat={getAmountFormat}
                  expandedGroup={expandedGroups.has(
                    `${m.componentId}_${g.code}`
                  )}
                  expand={() => expandGroup(`${m.componentId}_${g.code}`)}
                  collapse={() => collapseGroup(`${m.componentId}_${g.code}`)}
                  translate={translate}
                />
              ) : (
                <MessageSingle
                  key={ix}
                  message={g.entries[0]!}
                  code={g.code}
                  getAmountFormat={getAmountFormat}
                />
              )
            )}
          </AlertMessageBox>
        ))}
      </StyledDiv>
    </>
  );
}
