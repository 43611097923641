import * as React from "react";
import * as SharedState from "../../../../../shared-state";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import styled from "styled-components";
import { ComponentPriceRow } from "../../types";
import {
  GenericTable,
  GenericTableColumnDefinitionType,
  GenericTableRowType
} from "@genesys/ui-elements";

const Container = styled.div``;
interface PermissionType {
  readonly canSeeComponentPrice: boolean;
  readonly canSeeProductCode: boolean;
}

interface ComponentPrice {
  readonly description: string;
  readonly productText: string;
  readonly quantityShort: string;
  readonly partPrice: string;
}

export function ComponentPriceTable({
  tableComponents,
  componentOrder,
  numberLocale,
  currency,
  permissions,
  sharedState
}: {
  readonly permissions: PermissionType;
  readonly tableComponents: ReadonlyArray<ComponentPriceRow>;
  readonly currency: {
    readonly signAfter?: string;
    readonly signBefore?: string;
  };
  readonly numberLocale: string;
  readonly componentOrder: ReadonlyArray<string>;
  readonly sharedState: SharedState.State;
}): JSX.Element {
  const sortedTableComponents = componentOrder.reduce((a, b) => {
    const tc = tableComponents.find(c => c.id === b);
    return tc ? a.concat(tc) : a;
  }, [] as ReadonlyArray<ComponentPriceRow>);

  const rowsData = getRowsData(
    permissions,
    sortedTableComponents,
    currency,
    numberLocale
  );

  const columns: GenericTableColumnDefinitionType<ComponentPrice>[] = [
    {
      key: "description",
      header: sharedState.translate(LanguageTexts.description())
    },
    {
      key: "productText",
      header: sharedState.translate(LanguageTexts.productText())
    },
    {
      key: "quantityShort",
      header: sharedState.translate(LanguageTexts.quantityShort())
    },
    {
      key: "partPrice",
      header: sharedState.translate(LanguageTexts.partPrice()),
      align: "right"
    }
  ];
  return (
    <Container>
      <GenericTable columns={columns} data={rowsData} />
    </Container>
  );
}

function getRowsData(
  permissions: PermissionType,
  sortedTableComponents: ReadonlyArray<ComponentPriceRow>,
  currency: {
    readonly signAfter?: string;
    readonly signBefore?: string;
  },
  numberLocale: string
): ReadonlyArray<GenericTableRowType<ComponentPrice>> {
  const rows: Array<GenericTableRowType<ComponentPrice>> = [];

  for (const stc of sortedTableComponents) {
    const results: Array<string> = [];
    if (permissions.canSeeProductCode) {
      results.push(stc.productId);
    }
    results.push(stc.quantity.toString());
    if (permissions.canSeeComponentPrice) {
      results.push(
        stc.price
          ? (currency.signBefore || "") +
              Math.round(stc.price).toLocaleString(numberLocale) +
              (currency.signAfter ? " " + currency.signAfter : "")
          : ""
      );
    }
    rows.push({
      columnValuesForRow: {
        description: {
          value: stc.description
        },
        productText: {
          value: stc.productId,
          isHidden: !permissions.canSeeProductCode
        },
        quantityShort: {
          value: stc.quantity.toString()
        },
        partPrice: {
          value: stc.price
            ? (currency.signBefore || "") +
              Math.round(stc.price).toLocaleString(numberLocale) +
              (currency.signAfter ? " " + currency.signAfter : "")
            : "",
          isHidden: !permissions.canSeeComponentPrice
        }
      }
    });
  }

  return rows;
}
