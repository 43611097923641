type Immutable<Type> = {
  readonly [Property in keyof Type]: Type[Property];
};
const primaryColors = {
  muntersBlue: "#00ADF2",
  darkBlue: "#023442",
  lightBlue: "#F1F5FA",
  blue25: "#DDE4F0",
  blue50: "#9AB3D9",
  grey50: "#C5D0E5",
  grey75: "#8F9BB3",
  grey: "#646F86",
  gold: "#F0C530",
  green: "#46D580",
  red: "#E8767B",
  lightRed: "#f3babd"
};

// tslint:disable-next-line
export const PrimaryColors: Immutable<typeof primaryColors> = primaryColors;
