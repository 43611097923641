import {
  PropertyValueSet,
  PropertyFilter,
  PropertyValue
} from "@genesys/property";
const showOutdoorAirSource = "showoutdoorairsource";
const showReturnAirSource = "showreturnairsource";
const showCustomAirSource = "showcustomairsource";
const postMixingBoxExists = "postmixingboxexists";
const premMxingBoxExists = "premixingboxexists";
const preCoolerCount = "precoolercount";
const preHeaterExists = "preheaterexists";
const postCoolerExists = "postcoolerexists";
const postHeaterExists = "postheaterexists";
const reactPreHeaterExists = "reactpreheaterexists";
export function dspPreProcessOpc(
  newProperties: PropertyValueSet.PropertyValueSet
): PropertyValueSet.PropertyValueSet {
  let pvs = PropertyValueSet.Empty;

  // Show Outdoor air source
  // for react -> always so no filter needed.
  pvs = PropertyValueSet.merge(
    {
      [showOutdoorAirSource]: PropertyValue.fromInteger(1)
    },
    pvs
  );

  // Show Return air source
  pvs = PropertyValueSet.merge(
    {
      [showReturnAirSource]: PropertyValue.fromInteger(
        PropertyFilter.isValid(
          newProperties,
          PropertyFilter.fromStringOrEmpty("newprocessairsource=2")!
        ) ||
          PropertyFilter.isValid(
            newProperties,
            PropertyFilter.fromStringOrEmpty("newbasearrangement=3,5,8,23,24")!
          )
          ? 1
          : 0
      )
    },
    pvs
  );

  // Show Custom air source
  // always 0
  pvs = PropertyValueSet.merge(
    {
      [showCustomAirSource]: PropertyValue.fromInteger(0)
    },
    pvs
  );

  // Pre mixing box exists
  pvs = PropertyValueSet.merge(
    {
      [premMxingBoxExists]: PropertyValue.fromInteger(
        PropertyFilter.isValid(
          newProperties,
          PropertyFilter.fromStringOrEmpty(
            "newbasearrangement=3,5,6,8,9,23,24"
          )!
        )
          ? 1
          : 0
      )
    },
    pvs
  );

  // Post mixing box exists
  pvs = PropertyValueSet.merge(
    {
      [postMixingBoxExists]: PropertyValue.fromInteger(0)
    },
    pvs
  );

  // Pre heater exists
  pvs = PropertyValueSet.merge(
    {
      [preHeaterExists]: PropertyValue.fromInteger(
        PropertyFilter.isValid(
          newProperties,
          PropertyFilter.fromStringOrEmpty("newpreheater>0")!
        )
          ? 1
          : 0
      )
    },
    pvs
  );

  // Pre cooler count
  pvs = PropertyValueSet.merge(
    {
      [preCoolerCount]: PropertyValue.fromInteger(
        (PropertyFilter.isValid(
          newProperties,
          PropertyFilter.fromStringOrEmpty("newprecooler>0")
        )
          ? 1
          : 0) +
          (PropertyFilter.isValid(
            newProperties,
            PropertyFilter.fromStringOrEmpty("newprecooler2>0")
          )
            ? 1
            : 0)
      )
    },
    pvs
  );

  // React pre heater exists
  pvs = PropertyValueSet.merge(
    {
      [reactPreHeaterExists]: PropertyValue.fromInteger(0)
    },
    pvs
  );

  // Post cooler exists
  pvs = PropertyValueSet.merge(
    {
      [postCoolerExists]: PropertyValue.fromInteger(
        PropertyFilter.isValid(
          newProperties,
          PropertyFilter.fromStringOrEmpty("newpostcooler>0")
        )
          ? 1
          : 0
      )
    },
    pvs
  );

  // Post heater exists
  pvs = PropertyValueSet.merge(
    {
      [postHeaterExists]: PropertyValue.fromInteger(
        PropertyFilter.isValid(
          newProperties,
          PropertyFilter.fromStringOrEmpty("newpostheater>0")
        )
          ? 1
          : 0
      )
    },
    pvs
  );

  return pvs;
}
