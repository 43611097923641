import React from "react";
import { StyledTable, TableCell, TableRow, TableHead } from "../../atoms/table";
import { PrimaryColors } from "../../colors";
import {
  GenericTableColumnDefinitionType,
  GenericTableRowType,
  Props
} from "./types";

export function GenericTable<T>(props: Props<T>) {
  const alignHeader = props.defaultHeaderAlignment || "left";
  const alignRows = props.defaultRowAlignment || "left";

  return (
    <StyledTable alignRows={alignRows} alignHeader={alignHeader}>
      <TableHeader {...props} />
      <TableBody {...props} />
    </StyledTable>
  );
}

function TableBody<T>({ data, columns, highlightedRowStyle }: Props<T>) {
  return (
    <tbody>
      {data.map((row, i) => (
        <GenericTableRow
          key={i}
          row={row}
          columns={columns}
          higlightedRowStyle={highlightedRowStyle}
        />
      ))}
    </tbody>
  );
}
function GenericTableRow<T>({
  row,
  columns,
  higlightedRowStyle
}: {
  readonly row: GenericTableRowType<T>;
  readonly columns: Array<GenericTableColumnDefinitionType<T>>;
  readonly higlightedRowStyle?: React.CSSProperties;
}) {
  return (
    <TableRow
      rowStyle={getHighlitedRowStyle(!!row.highlighted, higlightedRowStyle)}
      onClick={event => {
        if (row.rowOnClick) {
          row.rowOnClick(event, row.columnValuesForRow);
        }
      }}
    >
      {columns.map((column, j) => {
        const cell = row.columnValuesForRow[column.key];
        return (
          <TableCell
            key={j}
            align={column.align}
            bold={cell.isBold || row.bold}
          >
            {cell.value as React.ReactNode}
          </TableCell>
        );
      })}
    </TableRow>
  );
}

function TableHeader<T>({ columns }: Props<T>) {
  return (
    <thead>
      <TableRow>
        {columns.map((column, i) => (
          <TableHead width={column.width} align={column.align} key={i}>
            {column.header}
          </TableHead>
        ))}
      </TableRow>
    </thead>
  );
}

function getHighlitedRowStyle(
  rowIsHighlighted: boolean,
  higlightedRowStyle?: React.CSSProperties
): React.CSSProperties {
  if (rowIsHighlighted) {
    if (higlightedRowStyle) {
      return higlightedRowStyle;
    }
    return { backgroundColor: PrimaryColors.blue25 };
  }

  return {};
}
