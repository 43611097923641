import {
  ctorsUnion,
  CtorsUnion
} from "@genesys/client-core/lib/constructors-union";
import { Cmd } from "@typescript-tea/core";
import { Quantity } from "@genesys/uom";
import { exhaustiveCheck } from "ts-exhaustive-check";
import * as SharedState from "../../../shared-state";
import * as Types from "./types";
import * as GraphQlTypes from "../../../graphql-types";
import * as Queries from "./queries";
import { BaseState } from "../step-state-base";
import * as PropertiesSelector from "../../../properties-selector";
import {
  PropertyValueSet,
  PropertyFilter,
  PropertyValue
} from "@genesys/property";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import {
  autoSelectSingleValidValue,
  defaultProperties
} from "./default-properties";

export const init = (
  sharedState: SharedState.State,
  base: BaseState
): readonly [Types.State, Cmd<Action>?] => [
  undefined,
  sharedState.graphQL.queryProductCmd<
    GraphQlTypes.WizardInitialConfiguration,
    GraphQlTypes.WizardInitialConfigurationVariables,
    Action
  >(
    Queries.query,
    {
      systemTypeInput: {
        systemTypeId: base.systemTypeId
      }
    },
    res => {
      const templates: ReadonlyArray<Types.Template> =
        res.product.systemType.templates.map(t => {
          const components: ReadonlyArray<Types.TemplateComponent> =
            t.components.map(c => ({
              id: c.id,
              productId: c.productId,
              label: c.label ?? undefined,
              properties: PropertyValueSet.fromString(c.properties ?? ""),
              sortNo: c.sortNo,
              visibleProperties: c.visibleProperties ?? [],
              propertyFilter: PropertyFilter.fromStringOrEmpty(
                c.propertyFilter ?? ""
              ),
              sections: c.componentSections.map(cs => ({
                id: cs.id,
                airstream: cs.airstream,
                productSectionId: cs.productSectionId,
                sortNo: cs.sortNo
              }))
            }));
          return {
            propertyFilter: PropertyFilter.fromStringOrEmpty(
              t.propertyFilter || ""
            ),
            components: components,
            climateDataDefaults: PropertyValueSet.fromString(
              t.climateDataDefaults ?? ""
            )
          };
        });
      const productMap = res.product.systemType.allProducts
        .filter(p =>
          templates.some(t => t.components.some(c => c.productId === p.id))
        )
        .reduce(
          (a, b) => ({
            ...a,
            [b.id]: {
              properties: b.properties.map(p =>
                parseProperty(p, sharedState, b.id, base.newProperties, true)
              ),
              boxSymbols: b.boxSymbols.map(parseBoxSymbol),
              boxConnectionPoints: b.boxConnectionPoints.map(
                parseBoxConnectionPoint
              ),
              boxTexts: [],
              boxLinePoints: b.boxLinePoints.map(parseBoxLine)
            }
          }),
          {
            [base.systemTypeId + "NEW"]: {
              properties: res.product.systemType.allProducts
                .find(p => p.id.endsWith("NEW"))!
                .properties.map(p =>
                  parseProperty(
                    p,
                    sharedState,
                    base.systemTypeId + "NEW",
                    base.newProperties,
                    true
                  )
                ),
              boxSymbols: [] as ReadonlyArray<Types.BoxSymbol>,
              boxConnectionPoints:
                [] as ReadonlyArray<Types.BoxConnectionPoint>,
              boxTexts: [] as ReadonlyArray<Types.BoxText>,
              boxLinePoints: [] as ReadonlyArray<Types.BoxLine>
            }
          }
        );

      return Action.loadData(
        {
          productMap: productMap,
          templates: templates,
          symbols: res.product.systemType.symbols.map(parseSymbolDef)
        },
        {
          ...base,
          newProperties: PropertyValueSet.merge(
            base.newProperties,
            defaultProperties(productMap[base.systemTypeId + "NEW"].properties)
          )
        }
      );
    }
  )
];

export const Action = ctorsUnion({
  loadData: (data: Types.Data, base: BaseState) => ({ data, base }),
  dispatchNewPropertiesSelector: (action: PropertiesSelector.Action) => ({
    action
  }),
  dispatchTemplateComponentPropertiesSelector: (
    id: string,
    action: PropertiesSelector.Action
  ) => ({
    id,
    action
  })
});
export type Action = CtorsUnion<typeof Action>;

export function update(
  action: Action,
  state: Types.State,
  sharedState: SharedState.State
): readonly [Types.State, Cmd<Action>?, ReadonlyArray<SharedState.Action>?] {
  const stateOk = (state: Types.State) => {
    if (!state) {
      return state;
    }

    return {
      ...state,
      ok:
        PropertiesSelector.isSelectionValid(
          {
            properties: state.newProperties
          },
          state.data.productMap[state.systemTypeId + "NEW"].properties.filter(
            p => PropertyFilter.isValid(state.newProperties, p.visibilityFilter)
          )
        ) &&
        Object.keys(state.divergentTemplateComponentMap).every(id => {
          const templateComponent = state.data.templates.reduce((a, b) => {
            if (a) {
              return a;
            }
            const hit = b.components.find(c => c.id === id);
            return hit ?? a;
          }, undefined)!;

          return PropertiesSelector.isSelectionValid(
            {
              properties: state.divergentTemplateComponentMap[id].properties
            },
            state.data.productMap[
              state.divergentTemplateComponentMap[id].productId
            ].properties.filter(p =>
              templateComponent.visibleProperties.includes(p.name)
            )
          );
        })
    };
  };

  switch (action.type) {
    case "loadData": {
      const template = action.data.templates.find(t =>
        PropertyFilter.isValid(action.base.newProperties, t.propertyFilter)
      )!;

      const componentsMap =
        template?.components
          .filter(
            c =>
              !c.productId.endsWith("OPC") &&
              c.visibleProperties.length &&
              PropertyFilter.isValid(
                action.base.newProperties,
                c.propertyFilter
              )
          )
          ?.reduce(
            (a, b) => ({
              ...a,
              [b.id]: {
                id: b.id,
                productId: b.productId,
                properties: PropertyValueSet.merge(
                  b.properties,
                  PropertyValueSet.merge(
                    action.base.newProperties,
                    defaultProperties(
                      action.data.productMap[b.productId].properties
                    )
                  )
                )
              }
            }),
            {}
          ) ?? {};

      return [
        stateOk({
          ...action.base,
          data: action.data,
          propertiesSelectorState: PropertiesSelector.init(
            action.base.newProperties
          ),
          divergentTemplateComponentMap: componentsMap,
          template: template
        })
      ];
    }
    case "dispatchNewPropertiesSelector": {
      if (!state) {
        return [state];
      }

      const [
        newPropertiesSelectorState,
        newPropertiesSelectorCmd,
        newPropertiesSelectorSharedAction
      ] = PropertiesSelector.update(
        action.action,
        {
          properties: state?.newProperties
        },
        sharedState,
        "SkipCalculateProperties"
      );

      const newProperties = PropertyValueSet.merge(
        autoSelectSingleValidValue(
          state.data.productMap[state.systemTypeId + "NEW"].properties,
          newPropertiesSelectorState.properties,
          ""
        ),
        state.newProperties
      );
      const template = state.data.templates.find(t =>
        PropertyFilter.isValid(newProperties, t.propertyFilter)
      )!;
      const componentsMap =
        template.components
          .filter(
            c =>
              !c.productId.endsWith("OPC") &&
              c.visibleProperties.length &&
              PropertyFilter.isValid(newProperties, c.propertyFilter)
          )
          ?.reduce((a, b) => {
            if (state.divergentTemplateComponentMap[b.id]) {
              return {
                ...a,
                [b.id]: state.divergentTemplateComponentMap[b.id]
              };
            } else {
              return {
                ...a,
                [b.id]: {
                  id: b.id,
                  productId: b.productId,
                  properties: PropertyValueSet.merge(
                    PropertyValueSet.merge(
                      b.properties,
                      PropertyValueSet.merge(
                        newProperties,
                        defaultProperties(
                          state.data.productMap[b.productId].properties
                        )
                      )
                    ),
                    state.divergentTemplateComponentMap[b.id]?.properties ??
                      PropertyValueSet.Empty
                  )
                }
              };
            }
          }, {}) ?? {};

      return [
        stateOk({
          ...state,
          newProperties: newProperties,
          propertiesSelectorState: {
            ...state.propertiesSelectorState,
            properties: newProperties
          },
          divergentTemplateComponentMap: componentsMap,
          template: template
        }),
        Cmd.map(Action.dispatchNewPropertiesSelector, newPropertiesSelectorCmd),
        newPropertiesSelectorSharedAction
          ? [newPropertiesSelectorSharedAction]
          : undefined
      ];
    }
    case "dispatchTemplateComponentPropertiesSelector": {
      if (!state) {
        return [state];
      }
      const [
        templateComponentPropertiesSelectorState,
        templateComponentPropertiesSelectorCmd,
        templateComponentPropertiesSelectorSharedAction
      ] = PropertiesSelector.update(
        action.action,
        {
          properties: state.divergentTemplateComponentMap[action.id].properties
        },
        sharedState,
        "SkipCalculateProperties"
      );
      return [
        stateOk({
          ...state,
          divergentTemplateComponentMap: {
            ...state.divergentTemplateComponentMap,
            [action.id]: {
              ...state.divergentTemplateComponentMap[action.id],
              properties: templateComponentPropertiesSelectorState.properties
            }
          }
        }),
        Cmd.map(
          Action.dispatchNewPropertiesSelector,
          templateComponentPropertiesSelectorCmd
        ),
        templateComponentPropertiesSelectorSharedAction
          ? [templateComponentPropertiesSelectorSharedAction]
          : undefined
      ];
    }
    default:
      exhaustiveCheck(action, true);
  }
}

function parseBoxSymbol(
  s: GraphQlTypes.WizardInitialConfiguration["product"]["systemType"]["allProducts"][0]["boxSymbols"][0]
): Types.BoxSymbol {
  return {
    id: s.id,
    box: s.box,
    symbolId: s.symbolId,
    positionX: s.positionX,
    positionY: s.positionY,
    positionZ: s.positionZ,
    mirrorX: s.mirrorX,
    mirrorY: s.mirrorY,
    rotation: s.rotation,
    sizeX: s.sizeX,
    sizeY: s.sizeY,
    absoluteRotationAndScale: s.absoluteRotationAndScale,
    useForSelectionBounds: s.useForSelectionBounds,
    diagramType: s.diagramType as Types.DiagramType,
    propertyFilter: PropertyFilter.fromStringOrEmpty(s.propertyFilter ?? "")
  };
}

function parseBoxConnectionPoint(
  cp: GraphQlTypes.WizardInitialConfiguration["product"]["systemType"]["allProducts"][0]["boxConnectionPoints"][0]
): Types.BoxConnectionPoint {
  return {
    id: cp.id,
    productSectionId: cp.productSectionId,
    box: cp.box,
    connectionType: cp.connectionType as Types.ConnectionType,
    positionX: cp.positionX,
    positionY: cp.positionY,
    positionZ: cp.positionZ,
    mirrorX: cp.mirrorX,
    mirrorY: cp.mirrorY,
    rotation: cp.rotation,
    hideStatePoint: cp.hideStatePoint,
    diagramType: cp.diagramType as Types.DiagramType,
    propertyFilter: PropertyFilter.fromStringOrEmpty(cp.propertyFilter ?? "")
  };
}

function parseBoxLine(
  l: GraphQlTypes.WizardInitialConfiguration["product"]["systemType"]["allProducts"][0]["boxLinePoints"][0]
): Types.BoxLine {
  return {
    id: l.id,
    box: l.box,
    connectionId: l.connectionId,
    lineType: l.lineType as Types.LineType,
    positionX: l.positionX,
    positionY: l.positionY,
    direction: l.direction as Types.Direction,
    diagramType: l.diagramType as Types.DiagramType,
    propertyFilter: PropertyFilter.fromStringOrEmpty(l.propertyFilter ?? "")
  };
}

function parseSymbolDef(
  s: GraphQlTypes.WizardInitialConfiguration["product"]["systemType"]["symbols"][0]
): Types.SymbolDefinition {
  return {
    width: s.width,
    height: s.height,
    svg: s.svg,
    symbolId: s.symbolId
  };
}

function parseProperty(
  p: GraphQlTypes.WizardInitialConfiguration["product"]["systemType"]["allProducts"][0]["properties"][0],
  sharedState: SharedState.State,
  productId: string,
  newProperties: PropertyValueSet.PropertyValueSet,
  useVisibilityFilter: boolean
): PropertiesSelector.PropertyInfo {
  return {
    name: p.name,
    quantity: p.quantity as Quantity.Quantity,
    group: p.groupName,
    sortNo: p.sortNo ?? 9999,
    defaultValues: p.defaultValues.map(d => ({
      value: PropertyValue.fromString(d.value)!,
      propertyFilter: PropertyFilter.fromStringOrEmpty(d.propertyFilter)
    })),
    validationFilter: PropertyFilter.fromStringOrEmpty(p.validationFilter),
    visibilityFilter: useVisibilityFilter
      ? PropertyFilter.fromStringOrEmpty(p.visibilityFilter)
      : PropertyFilter.Empty,
    items: p.values
      .filter(
        v =>
          (!p.name.startsWith("range") ||
            PropertyValue.equals(
              PropertyValue.fromString(v.value)!,
              PropertyValueSet.get(p.name, newProperties)!
            )) &&
          PropertyFilter.isValid(
            newProperties,
            PropertyFilter.fromStringOrEmpty(v.rangeFilter)
          )
      )
      .map(v => ({
        id: v.value,
        sortNo: v.sortNo ?? 9999,
        image: v.image ?? undefined,
        value: PropertyValue.fromString(v.value)!,
        validationFilter: PropertyFilter.fromStringOrEmpty(v.validationFilter),
        rangeFilter: PropertyFilter.fromStringOrEmpty(v.rangeFilter),
        text: sharedState.translate(
          LanguageTexts.productPropertyValue(
            productId,
            p.name,
            parseInt(v.value, 10)
          )
        ),
        descriptionValuesTexts: v.descriptionTexts.map(x => ({
          id: x.id,
          propertyFilter: PropertyFilter.fromStringOrEmpty(x.propertyFilter),
          language: x.language,
          text: x.text
        }))
      })),
    valueSources: [],
    conversionParameters: p.quantityConversionParams,
    descriptionTexts: p.descriptionTexts.map(x => ({
      id: x.id,
      propertyFilter: PropertyFilter.fromStringOrEmpty(x.propertyFilter),
      language: x.language,
      text: x.text
    }))
  };
}
