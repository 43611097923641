import { exhaustiveCheck } from "ts-exhaustive-check";
import {
  CtorsUnion,
  ctorsUnion
} from "@genesys/client-core/lib/constructors-union";
import { Cmd } from "@typescript-tea/core";
import gql from "graphql-tag";
import * as SharedState from "../../../shared-state";
import * as GraphQlTypes from "../../../graphql-types";
import * as SystemStatusEnum from "@genesys/shared/lib/enums/system-status";
import * as System from "../../system";

const priceProductQuery = gql`
  query PriceProduct($systemTypeInput: GetSystemTypeInputType!) {
    product {
      genesysNumberPrefixes {
        id
        pricingNo
      }
      salesOrganisations {
        id
        name
        defaultCurrency {
          id
        }
      }
      systemType(input: $systemTypeInput) {
        id
        systemTypePriceSetting {
          id
          model
          displayException
        }
      }
    }
  }
`;

const systemHasQuery = gql`
  query SystemHasPrice($systemId: ID!) {
    user {
      system(id: $systemId) {
        validityDate
        systemComponentPrice {
          componentId
          priceRows {
            priceType
            value
          }
        }
        pricingInformation {
          id
          pricingNo
          revisionNo
        }
      }
    }
  }
`;

export type State = {
  readonly priceProductQueryData: GraphQlTypes.PriceProduct | undefined;
  readonly systemHasPriceQueryData: GraphQlTypes.SystemHasPrice | undefined;

  readonly currencyCode: string | undefined;
  readonly salesOrganisation: string | undefined;
  readonly masterCurrencyCode: string | undefined;
  readonly masterSalesOrganisation: string | undefined;
  readonly extendedInformationIsOpen: boolean;
  readonly loadingMoreData: boolean;
  readonly valuesHasChanged: boolean;
};

export const init = (
  sharedState: SharedState.State,
  sysTypeID: string
): [State, Cmd<Action>] => {
  return [
    {
      priceProductQueryData: undefined,
      systemHasPriceQueryData: undefined,
      currencyCode: undefined,
      salesOrganisation: undefined,
      masterSalesOrganisation: undefined,
      masterCurrencyCode: undefined,
      extendedInformationIsOpen: false,
      loadingMoreData: false,
      valuesHasChanged: false
    },
    sharedState.graphQL.queryProductCmd<
      GraphQlTypes.PriceProduct,
      GraphQlTypes.PriceProductVariables,
      Action
    >(
      priceProductQuery,
      {
        systemTypeInput: { systemTypeId: sysTypeID }
      },
      Action.priceProductQueryRecieved
    )
  ];
};

export const Action = ctorsUnion({
  priceProductQueryRecieved: (data: GraphQlTypes.PriceProduct) => ({
    data
  }),
  systemHasPriceQueryRecieved: (data: GraphQlTypes.SystemHasPrice) => ({
    data
  }),
  setSalesOrganisation: (salesOrganisation: string) => ({
    salesOrganisation
  }),
  setCurrency: (currencyCode: string) => ({
    currencyCode
  }),
  setMasterSalesOrganisation: (masterSalesOrganisation: string) => ({
    masterSalesOrganisation
  }),
  setMasterCurrencyCode: (masterCurrencyCode: string) => ({
    masterCurrencyCode
  }),
  toggleExtendedInformation: () => ({})
});
export type Action = CtorsUnion<typeof Action>;

export function update(
  action: Action,
  state: State,
  sharedState: SharedState.State,
  system: System.System
): [State, Cmd<Action>?] {
  switch (action.type) {
    case "priceProductQueryRecieved": {
      const fetchMoreData = !(
        system.status < SystemStatusEnum.SystemStatus.PriceCalculationSuccess
      );
      return [
        {
          ...state,
          priceProductQueryData: action.data,
          loadingMoreData: fetchMoreData ? true : false
        },
        fetchMoreData
          ? sharedState.graphQL.queryUserCmd<
              GraphQlTypes.SystemHasPrice,
              GraphQlTypes.SystemHasPriceVariables,
              Action
            >(
              systemHasQuery,
              {
                systemId: system.id
              },
              Action.systemHasPriceQueryRecieved
            )
          : undefined
      ];
    }
    case "systemHasPriceQueryRecieved": {
      return [
        {
          ...state,
          systemHasPriceQueryData: action.data,
          loadingMoreData: false
        }
      ];
    }

    case "setSalesOrganisation": {
      return [
        {
          ...state,
          salesOrganisation: action.salesOrganisation,
          valuesHasChanged: true
        }
      ];
    }

    case "setCurrency": {
      return [
        {
          ...state,
          currencyCode: action.currencyCode,
          valuesHasChanged: true
        }
      ];
    }

    case "setMasterSalesOrganisation": {
      return [
        {
          ...state,
          masterSalesOrganisation: action.masterSalesOrganisation,
          valuesHasChanged: true
        }
      ];
    }

    case "setMasterCurrencyCode": {
      return [
        {
          ...state,
          masterCurrencyCode: action.masterCurrencyCode,
          valuesHasChanged: true
        }
      ];
    }
    case "toggleExtendedInformation": {
      return [
        {
          ...state,
          extendedInformationIsOpen: !state.extendedInformationIsOpen
        }
      ];
    }

    default:
      return exhaustiveCheck(action, true);
  }
}

export function getSystemPriceInformation(state: State, system: System.System) {
  return {
    systemId: system.id,
    salesOrganisationId: state.salesOrganisation || system.salesOrganisationId,
    currencyCode: state.currencyCode || system.currencyCode,
    masterCurrencyCode: state.masterCurrencyCode || system.masterCurrencyCode,
    masterOrganisationId:
      state.masterSalesOrganisation || system.masterSalesOrganisationId
  };
}
