import * as SharedState from "../shared-state";
import * as GraphQlTypes from "../graphql-types";
import * as Authorization from "@genesys/shared/lib/authorization";
import * as Types from "./types";
import { dataPointsQuery, defaultWmo } from "../climate-selector";
import {
  PropertyValueSet,
  PropertyValue,
  PropertyFilter
} from "@genesys/property";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as ComponentsMessages from "@genesys/shared/lib/components-messages";
import { MessagePart } from "@genesys/shared/lib/components-messages";
import * as ScreenAmounts from "@genesys/shared/lib/screen-amounts";
import { getValue } from "@genesys/shared/lib/product-properties";
import { Format, Quantity, UnitsFormat } from "@genesys/uom";
import { exhaustiveCheck } from "ts-exhaustive-check";
import { calculateNewSystem } from "./queries";
import { Action } from "./state";
import { Dispatch } from "@typescript-tea/core";
import * as AuthorizationTools from "@genesys/shared/lib/authorization";
import { v4 } from "uuid";
import * as SystemStatus from "@genesys/shared/lib/enums/system-status";
import * as OperatingCaseSelector from "../operating-case-selector";
import { PropertyInfo } from "../properties-selector";
import {
  convertMoistureLoadToOPC,
  MlOpcaseResult
} from "../moisture-load-calculation";
import { opcProductData } from "./data";

export async function calculateSystem(
  createSystemInput: GraphQlTypes.DrySizeCalculateNewSystemVariables,
  sharedState: SharedState.State
): Promise<GraphQlTypes.DrySizeCalculateNewSystem> {
  const result = await sharedState.graphQL.queryUser<
    GraphQlTypes.DrySizeCalculateNewSystem,
    GraphQlTypes.DrySizeCalculateNewSystemVariables
  >(calculateNewSystem, createSystemInput);

  return result;
}

export async function FetchUserLocationDataPointsOrDefault(
  sharedState: SharedState.State
): Promise<GraphQlTypes.ClimateSelectorDataPointsProductQuery> {
  let dataPoints;
  const locationIds = [
    sharedState.user.settings.climate.location || defaultWmo,
    defaultWmo
  ];
  do {
    if (locationIds.length === 0) {
      throw new Error("No datapoints was found");
    }
    dataPoints = await sharedState.graphQL.queryProduct<
      GraphQlTypes.ClimateSelectorDataPointsProductQuery,
      GraphQlTypes.ClimateSelectorDataPointsProductQueryVariables
    >(dataPointsQuery, {
      locationId: locationIds.shift()!
    });
  } while (!dataPoints.product.dataPointsForLocationId);

  return dataPoints;
}

export function buildNewPropertiesSet(
  propertySetOptions: ReadonlyArray<Types.PropertySetOption>,
  properties: PropertyValueSet.PropertyValueSet
): ReadonlyArray<PropertyValueSet.PropertyValueSet> {
  const newPropertiesSet = propertySetOptions.reduce(
    (soFar, current) => {
      const propertyExists = soFar.some(pvs =>
        PropertyValueSet.hasProperty(current.propertyName, pvs)
      );

      if (propertyExists) {
        const newProperties = soFar.concat(
          soFar.map(pvs =>
            PropertyValueSet.setInteger(
              current.propertyName,
              current.value,
              pvs
            )
          )
        );
        return newProperties.reduce((soFar, current) => {
          const duplicateExists = soFar.some(pvs =>
            PropertyValueSet.equals(pvs, current)
          );
          if (duplicateExists) {
            return soFar;
          } else {
            return soFar.concat([current]);
          }
        }, [] as Array<PropertyValueSet.PropertyValueSet>);
      } else {
        return soFar.map(pvs =>
          PropertyValueSet.setInteger(current.propertyName, current.value, pvs)
        );
      }
    },
    [properties]
  );

  return newPropertiesSet;
}

export function getProductProperties(
  systemType: GraphQlTypes.DrySizeProductQuery["product"]["systemTypes"][0],
  productId: "OPC" | "NEW" | "SYS",
  sharedState: SharedState.State
): Array<PropertyInfo> {
  const product =
    productId === "OPC"
      ? systemType.opc
      : productId === "NEW"
      ? systemType.new
      : systemType.sys;
  return product.properties.map(p =>
    parseProperty(p, sharedState, product.id, true)
  );
}

export function canUserCreateSystemType(
  systemType: GraphQlTypes.DrySizeProductQuery["product"]["systemTypes"][0],
  sharedState: SharedState.State
): boolean {
  const isDeveloper = Authorization.checkPermission(
    sharedState.user.applicationClaims,
    Authorization.genesysUserClaims.developer
  );
  if (isDeveloper) {
    return true;
  }
  const values =
    Authorization.getClaimValues(
      sharedState.user.applicationClaims,
      Authorization.systemTypeClaims.create
    ) ?? [];
  return values
    .map(v => v.split(";")[0].toUpperCase())
    .some(v => v === systemType.id.toUpperCase());
}

export function parseNewMappings(
  newMappings:
    | GraphQlTypes.DrySizeProductQuery["product"]["systemTypes"][0]["new"]["newPropertiesMapping"]
    | null
    | undefined
): ReadonlyArray<Types.NewMapping> {
  if (!newMappings) {
    return [];
  }

  return newMappings.rows.reduce((soFar, current) => {
    const pvs = PropertyValueSet.fromString(current.values);

    const filter = PropertyFilter.fromString(
      PropertyValueSet.getText("Filter", pvs) ?? ""
    )!;
    const name = PropertyValueSet.getText("Name", pvs);
    const value = PropertyValueSet.getText("Value", pvs) ?? "";

    if (name !== undefined) {
      const mapping: Types.NewMapping = {
        filter: filter,
        name: name,
        value: value
      };
      return soFar.concat(mapping);
    } else {
      return soFar;
    }
  }, [] as ReadonlyArray<Types.NewMapping>);
}

export function parseFlowLimits(
  flowLimitationsSI:
    | GraphQlTypes.DrySizeProductQuery["product"]["systemTypes"][0]["new"]["flowLimitationsSI"]
    | null
    | undefined
): ReadonlyArray<Types.FlowLimits> {
  if (!flowLimitationsSI) {
    return [];
  }
  const flowLimits = flowLimitationsSI.rows.reduce((soFar, current) => {
    const pvs = PropertyValueSet.fromString(current.values);
    const nominalFlow = PropertyValueSet.getAmount<"MassFlow">(
      "NominalFlow",
      pvs
    );
    const dehumCapacity = PropertyValueSet.getAmount<"MassFlow">(
      "NominalDehumCapacity",
      pvs
    );

    const filter = PropertyFilter.fromString(
      PropertyValueSet.getText("Filter", pvs) ?? ""
    );

    if (!filter || !nominalFlow || !dehumCapacity) {
      return soFar;
    } else {
      return soFar.concat([
        {
          filter: filter,
          nominalFlow: nominalFlow,
          nominalDehumCapacity: dehumCapacity
        }
      ]);
    }
  }, [] as ReadonlyArray<Types.FlowLimits>);

  return flowLimits;
}

export function parseDehumidificationThresholds(
  dehumidificationThresholdTable:
    | GraphQlTypes.DrySizeProductQuery["product"]["systemTypes"][0]["dhu"]["dehumidificationThreshold"]
    | null
    | undefined
): ReadonlyArray<Types.DehumidificationThreshold> {
  if (!dehumidificationThresholdTable) {
    return [];
  }
  const dehumidificationThresholds = dehumidificationThresholdTable.rows.reduce(
    (soFar, current) => {
      const pvs = PropertyValueSet.fromString(current.values);
      const dehumThreshold = PropertyValueSet.getAmount<Quantity.HumidityRatio>(
        "Threshold",
        pvs
      );
      const filter = PropertyFilter.fromString(
        PropertyValueSet.getText("Filter", pvs) ?? ""
      )!;

      if (!dehumThreshold) {
        return soFar;
      } else {
        return soFar.concat([
          {
            filter: filter,
            dehumThreshold: dehumThreshold
          }
        ]);
      }
    },
    [] as ReadonlyArray<Types.DehumidificationThreshold>
  );

  return dehumidificationThresholds;
}

function parseProperty(
  p: GraphQlTypes.DrySizeProductQuery["product"]["systemTypes"][0]["new"]["properties"][0],
  sharedState: SharedState.State,
  productId: string,
  useVisibilityFilter: boolean
): PropertyInfo {
  return {
    name: p.name,
    quantity: p.quantity as Quantity.Quantity,
    group: p.groupName,
    sortNo: p.sortNo ?? 9999,
    defaultValues: p.defaultValues.map(d => ({
      value: PropertyValue.fromString(d.value)!,
      propertyFilter: PropertyFilter.fromStringOrEmpty(d.propertyFilter)
    })),
    validationFilter: PropertyFilter.fromStringOrEmpty(p.validationFilter),
    visibilityFilter: useVisibilityFilter
      ? PropertyFilter.fromStringOrEmpty(p.visibilityFilter)
      : PropertyFilter.Empty,
    items: p.values.map(v => ({
      id: v.id,
      sortNo: v.sortNo ?? 9999,
      image: v.image ?? undefined,
      value: PropertyValue.fromString(v.value)!,
      validationFilter: PropertyFilter.fromStringOrEmpty(v.validationFilter),
      text: sharedState.translate(
        LanguageTexts.productPropertyValue(
          productId,
          p.name,
          parseInt(v.value, 10)
        )
      ),
      descriptionValuesTexts: [],
      rangeFilter: PropertyFilter.fromStringOrEmpty(v.rangeFilter)
    })),
    valueSources: [],
    conversionParameters: p.quantityConversionParams,
    descriptionTexts: []
  };
}

export function parseCalculationResult(
  result: GraphQlTypes.DrySizeCalculateNewSystem,
  sharedState: SharedState.State,
  systemTypeCaseFilter: ReadonlyArray<Types.CaseFilter>
): ComponentsMessages.RootMessage {
  const helpTexts: ReadonlyArray<{
    readonly id: string;
    readonly helpTextTable:
      | {
          readonly rows: ReadonlyArray<{
            readonly propertyFilter?: string | null | undefined;
            readonly values: string;
          }>;
        }
      | null
      | undefined;
  }> = result.product.systemType.allProducts.map(p => ({
    id: p.id,
    helpTextTable: p.helpText
      ? {
          rows: p.helpText.rows.map(r => ({
            propertyFilter: r.propertyFilter,
            values: r.values
          }))
        }
      : undefined
  }));

  const productsCaseFilters = result.product.systemType.allProducts.map(p => ({
    productId: p.id,
    caseFilters: parseProductCaseFilter(p.caseFilter)
  }));

  const componentMessages =
    result.user.calculateNewSystem.componentsWithErrors.reduce(
      (soFar, current) => {
        return soFar.concat(
          current.messages.map(m => ({
            id: m.id,
            componentId: current.componentId,
            productId: current.productId,
            properties: PropertyValueSet.fromString(current.properties),
            code: m.messageCode,
            severity: m.messageSeverity,
            parameters: PropertyValueSet.fromString(m.messageParameters ?? ""),
            operatingCaseResultId: m.operatingCaseResultId ?? undefined
          }))
        );
      },
      [] as ReadonlyArray<{
        readonly id: string;
        readonly componentId: string;
        readonly productId: string;
        readonly properties: PropertyValueSet.PropertyValueSet;
        readonly code: number;
        readonly severity: number;
        readonly parameters: PropertyValueSet.PropertyValueSet;
        readonly operatingCaseResultId: string | undefined;
      }>
    );

  const operatingCaseResults =
    result.user.calculateNewSystem.operatingCases.reduce<
      ReadonlyArray<ComponentsMessages.OperatingCaseResult>
    >((a, b) => {
      return [
        ...a,
        ...b.results.map(r => ({
          id: r.id,
          componentId: r.componentId,
          calculationType: r.calculationType,
          caseType: b.caseType,
          operatingCase: {
            id: b.id,
            caseName: b.caseName,
            customCaseName: b.customCaseName ?? undefined,
            sortNo: b.sortNo
          }
        }))
      ];
    }, []);

  const claimFilteredComponentMessages =
    ComponentsMessages.getClaimFilteredComponentMessages(
      componentMessages,
      operatingCaseResults,
      systemTypeCaseFilter,
      productsCaseFilters,
      sharedState.user.applicationClaims
    );

  const rootMessages = ComponentsMessages.getRootMessages(
    sharedState.translate,
    helpTexts,
    result.product.systemType.id,
    claimFilteredComponentMessages,
    operatingCaseResults
  );

  return rootMessages;
}

export function formattedMessage(
  errorCode: number,
  parts: ReadonlyArray<MessagePart>,
  getAmountFormat: ScreenAmounts.GetAmountFormat
) {
  const fieldGroup = "Errors";

  const messageParts = parts.map(p => {
    switch (p.kind) {
      case "MessagePartText": {
        return p.text;
      }
      case "MessagePartAmount": {
        const fieldName = `${errorCode}_${p.propertyName}`;
        if (getAmountFormat) {
          const amountFormat = getAmountFormat(fieldGroup, fieldName, p.amount);
          const value = getValue(
            PropertyValue.fromAmount(p.amount),
            amountFormat
          );
          const unitFormat = Format.getUnitFormat(
            amountFormat.unit,
            UnitsFormat
          );
          return value + unitFormat?.label;
        } else {
          return "";
        }
      }
      default: {
        exhaustiveCheck(p);
      }
    }
  });

  return messageParts.reduce((soFar, current) => {
    return soFar + current + " ";
  }, "");
}

export function createSystem(
  dispatch: Dispatch<Action>,
  sharedState: SharedState.State,
  systemName: string,
  systemTypeId: string,
  newProperties: PropertyValueSet.PropertyValueSet,
  operatingCases: ReadonlyArray<PropertyValueSet.PropertyValueSet>,
  climateData: PropertyValueSet.PropertyValueSet,
  divergentSysComponent:
    | {
        readonly id: string;
        readonly properties: PropertyValueSet.PropertyValueSet;
      }
    | undefined,
  moistureLoadInfo: Types.MoistureLoadInfo | undefined,
  opcSelectorPvs: PropertyValueSet.PropertyValueSet
): void {
  const primarySalesOrganisationFromClaim = AuthorizationTools.getClaimValues(
    sharedState.user.applicationClaims,
    AuthorizationTools.genesysUserClaims.primarySalesOrganisation
  );

  return dispatch(
    Action.createSystem({
      input: [
        {
          id: v4(),
          name: moistureLoadInfo?.name || systemName,
          moistureLoadId: moistureLoadInfo?.moistureLoadId,
          addMCompare: false,
          labels: moistureLoadInfo?.labels,
          climateData: PropertyValueSet.toString(climateData),
          currencyCode: sharedState.user.settings.currency,
          generateBinCases: false,
          newProperties: PropertyValueSet.toString(newProperties),
          operatingCases: operatingCases
            .map(opc => updateExternalStaticPressure(opcSelectorPvs, opc))
            .map(oc => PropertyValueSet.toString(oc)),
          salesOrganisationId:
            sharedState.user.settings.defaultSalesOrganisation ||
            (primarySalesOrganisationFromClaim &&
              primarySalesOrganisationFromClaim[0]) ||
            sharedState.user.salesOrganisations[0].id,
          systemTypeId: systemTypeId,
          targetStatus: SystemStatus.SystemStatus.PriceCalculationSuccess,
          templateComponents: divergentSysComponent
            ? [
                {
                  id: divergentSysComponent.id,
                  properties: PropertyValueSet.toString(
                    divergentSysComponent.properties
                  )
                }
              ]
            : []
        }
      ]
    })
  );
}

export function calculateSystems(
  dispatch: Dispatch<Action>,
  sharedState: SharedState.State,
  systemInputs: ReadonlyArray<{
    readonly systemName: string;
    readonly systemTypeId: string;
    readonly newProperties: PropertyValueSet.PropertyValueSet;
    readonly operatingCases: ReadonlyArray<PropertyValueSet.PropertyValueSet>;
    readonly climateData: PropertyValueSet.PropertyValueSet;
    readonly systemIdentifier: string;
  }>,
  opcSelectorPvs: PropertyValueSet.PropertyValueSet
): void {
  const primarySalesOrganisationFromClaim = AuthorizationTools.getClaimValues(
    sharedState.user.applicationClaims,
    AuthorizationTools.genesysUserClaims.primarySalesOrganisation
  );

  return dispatch(
    Action.calculateSystems(
      systemInputs.map(s => ({
        systemIdentifier: s.systemIdentifier,
        createSystemInput: {
          systemSettings: {
            addMCompare: false,
            labels: undefined,
            climateData: PropertyValueSet.toString(s.climateData),
            currencyCode: sharedState.user.settings.currency,
            generateBinCases: false,
            id: v4(),
            name: s.systemName,
            newProperties: PropertyValueSet.toString(s.newProperties),
            operatingCases: s.operatingCases
              .map(opc => updateExternalStaticPressure(opcSelectorPvs, opc))
              .map(oc => PropertyValueSet.toString(oc)),
            salesOrganisationId:
              sharedState.user.settings.defaultSalesOrganisation ||
              (primarySalesOrganisationFromClaim &&
                primarySalesOrganisationFromClaim[0]) ||
              sharedState.user.salesOrganisations[0].id,
            systemTypeId: s.systemTypeId,
            targetStatus: SystemStatus.SystemStatus.PriceCalculationSuccess,
            templateComponents: []
          },
          systemTypeId: { systemTypeId: s.systemTypeId }
        }
      }))
    )
  );
}

export function getOperatingCase(
  moistureLoadOpcase: MlOpcaseResult,
  climateSettings: PropertyValueSet.PropertyValueSet,
  translate: LanguageTexts.Translate
) {
  const defaultOperatingCasePvs = PropertyValueSet.fromString(
    `sortno=0;atmosphericpressure=101325:Pascal;casename="Summer";outdoordata=0;outdoorairtemperature=20:Celsius;outdoorairhumidity=10:GramPerKilogram;returnairtemperature=20:Celsius;returnairhumidity=10:GramPerKilogram;customairtemperature=20:Celsius;customairhumidity=10:GramPerKilogram;processinletexternalstatic=0:Pascal;processoutletexternalstatic=250:Pascal;reactinletexternalstatic=0:Pascal;reactoutletexternalstatic=250:Pascal;supplyoutletairflow=3000:StandardCubicMeterPerHour;supplytargethumidity=3:GramPerKilogram;premixingboxairflow=0:StandardCubicMeterPerHour`
  );

  const defaultOperatingCase =
    OperatingCaseSelector.createInitialOperatingCases(
      climateSettings,
      [{ id: "0", properties: defaultOperatingCasePvs }],
      opcProductData.properties.map((p, ix) => ({
        id: ix.toString(),
        name: p.name,
        quantity: p.quantity,
        valueSources: p.valueSources.map(v => ({
          value: v.value,
          propertyValueSourceId: v.propertyValueSourceId,
          parameters: v.parameters ?? null,
          propertyFilter: PropertyFilter.toString(v.propertyFilter),
          claimFilter: v.claimFilter
        })),
        items: p.items.map(v => ({
          id: v.id,
          value: PropertyValue.toString(v.value)
        })),
        defaultValues: []
      })),
      translate
    );

  const effectiveOperatingCase = convertMoistureLoadToOPC(
    [defaultOperatingCase[0].settings],
    moistureLoadOpcase.opcResult,
    opcProductData.properties
  )[0];

  const dehumidifierLoadPvs = PropertyValueSet.keepProperties(
    [
      "mlcbuildingmoistureload",
      "mlcfreshflow",
      "mlcfreshtemperature",
      "mlcfreshabshumidity"
    ],
    PropertyValueSet.fromString(moistureLoadOpcase.opcResult[0].settings)
  );

  return PropertyValueSet.merge(
    effectiveOperatingCase.settings,
    dehumidifierLoadPvs
  );
}

export function parseCaseFilter(
  caseFilter: GraphQlTypes.DrySizeProductQuery["product"]["systemTypes"][0]["systemTypeCaseFilter"][0]
): Types.CaseFilter {
  return {
    caseType: caseFilter.caseType,
    calculationType: caseFilter.calculationType,
    claimFilter: ""
  };
}

export function parseProductCaseFilter(
  caseFilter?:
    | GraphQlTypes.DrySizeCalculateNewSystem["product"]["systemType"]["allProducts"][0]["caseFilter"]
    | null
    | undefined
): ReadonlyArray<Types.CaseFilter> {
  if (!caseFilter) {
    return [];
  }
  return caseFilter.rows.map(r => {
    const pvs = PropertyValueSet.fromString(r.values);
    const caseType = PropertyValueSet.getText("CaseType", pvs)?.toUpperCase();
    const calculationType = PropertyValueSet.getText(
      "CalculationType",
      pvs
    )?.toUpperCase();
    const claimFilter = PropertyValueSet.getText("ClaimFilter", pvs) ?? "";
    return {
      caseType:
        GraphQlTypes.CaseType[caseType as keyof typeof GraphQlTypes.CaseType],
      calculationType:
        GraphQlTypes.ComponentCalculationType[
          calculationType as keyof typeof calculationType
        ],
      claimFilter: claimFilter
    };
  });
}

function updateExternalStaticPressure(
  opcSelectorPvs: PropertyValueSet.PropertyValueSet,
  systemOpcPvs: PropertyValueSet.PropertyValueSet
) {
  const externalStaticPressuresPvs = PropertyValueSet.keepProperties(
    ["processoutletexternalstatic", "reactoutletexternalstatic"],
    opcSelectorPvs
  );
  return PropertyValueSet.merge(externalStaticPressuresPvs, systemOpcPvs);
}

export function parseOpcPreProcessRows(
  opcPreProcessTable:
    | GraphQlTypes.DrySizeProductQuery["product"]["systemTypes"][0]["new"]["opcPreProcess"]
    | null
    | undefined
): ReadonlyArray<Types.OpcPreProcessRow> {
  if (!opcPreProcessTable) {
    return [];
  }
  const preProcessRows = opcPreProcessTable.rows.map(row => {
    const pvs = PropertyValueSet.fromString(row.values);

    const propertyName = PropertyValueSet.getText("PropertyName", pvs) ?? "";
    const propertyFilter = PropertyFilter.fromString(
      PropertyValueSet.getText("PropertyFilter", pvs) ?? ""
    )!;
    const IfValidValue =
      PropertyValueSet.getAmount<Quantity.Dimensionless>("IfValidValue", pvs)
        ?.value ?? 0;
    const IfNotValidValue =
      PropertyValueSet.getAmount<Quantity.Dimensionless>("IfNotValidValue", pvs)
        ?.value ?? undefined;

    return { propertyName, propertyFilter, IfValidValue, IfNotValidValue };
  });

  return preProcessRows;
}

// tslint:disable-next-line
