// import * as React from "react";
import * as FlowDiagram from "@genesys/flow-diagram-gen2";
import * as Product from "./product";
import * as SharedState from "../../../shared-state";
import { Quantity } from "@genesys/uom";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import { getValue } from "@genesys/shared/lib/product-properties";
import { PropertyValue } from "@genesys/property";
import * as Types from "./types";
import * as AbstractImage from "abstract-image";

export interface DiagramProps {
  readonly productMap: Types.ProductMap;
  readonly sharedState: SharedState.State;
  readonly symbols: ReadonlyArray<Product.SymbolDefinition>;
  readonly templateComponents: ReadonlyArray<Types.TemplateComponent>;
  readonly systemTypeId: string;
}

export function Diagram(props: DiagramProps): JSX.Element {
  const diagramExpandSize = 40;

  const products: ReadonlyArray<FlowDiagram.Types.Product> = Object.keys(
    props.productMap
  ).map(k => ({
    ...props.productMap[k],
    id: k,
    properties: props.productMap[k].properties.map(p => ({
      ...p,
      quantity: p.quantity as Quantity.Quantity
    }))
  }));

  const diagramType = products.find(
    p =>
      p.boxConnectionPoints.find(b => b.diagramType === "UnitConfiguration") ||
      p.boxLinePoints.find(b => b.diagramType === "UnitConfiguration") ||
      p.boxSymbols.find(b => b.diagramType === "UnitConfiguration") ||
      p.boxTexts.find(b => b.diagramType === "UnitConfiguration")
  )
    ? "UnitConfiguration"
    : "FlowDiagram";

  const diagram = FlowDiagram.buildDiagram(
    false,
    diagramType,
    false,
    products,
    diagramSystem(props.templateComponents),
    (productId, propertyName, propertyValue) =>
      props.sharedState.translate(
        LanguageTexts.productPropertyValue(
          productId,
          propertyName,
          propertyValue
        )
      ),
    (key: string) =>
      props.sharedState.translate(
        LanguageTexts.dynamicText(key, props.systemTypeId)
      ),
    (key: string) =>
      props.sharedState.translate(
        LanguageTexts.curlyTranslate(key, props.systemTypeId)
      ),
    (
      fieldGroup: string,
      fieldName: string,
      quantity: Quantity.Quantity,
      property: PropertyValue.PropertyValue
    ) =>
      getValue(
        property,
        props.sharedState.screenAmounts.getAmountFormat(
          fieldGroup,
          fieldName,
          quantity
        )
      ),
    diagramExpandSize
  );

  const abstractImage = FlowDiagram.buildAbstractImage(
    diagram,
    [],
    [],
    [],
    props.symbols,
    diagramExpandSize
  );
  const svg = AbstractImage.createReactSvg(abstractImage);
  return svg;
}

function diagramSystem(
  templateComponents: ReadonlyArray<Types.TemplateComponent>
): FlowDiagram.Types.System {
  const airstreamMap = templateComponents.reduce(
    (a, b) => {
      b.sections.forEach(cs => {
        const keyExists = a[cs.airstream];
        if (keyExists) {
          a[cs.airstream].push({
            id: cs.id,
            productSectionId: cs.productSectionId,
            sortNo: cs.sortNo
          });
        } else {
          a[cs.airstream] = [
            {
              id: cs.id,
              productSectionId: cs.productSectionId,
              sortNo: cs.sortNo
            }
          ];
        }
      });
      return a;
    },
    {} as {
      //tslint:disable-next-line
      [key: string]: Array<
        FlowDiagram.Types.ComponentSection & {
          readonly sortNo: number;
        }
      >;
    }
  );

  const airstreams = Object.keys(airstreamMap).map(k => ({
    id: k,
    componentSections: [...airstreamMap[k]].sort((a, b) =>
      a.sortNo - b.sortNo < 0 ? -1 : 1
    )
  }));

  return {
    components: templateComponents.map(c => {
      const sections: ReadonlyArray<FlowDiagram.Types.ComponentSection> =
        c.sections.map(s => ({
          id: s.id,
          productSectionId: s.productSectionId
        }));
      return {
        id: c.id,
        label: c.label,
        productId: c.productId,
        properties: c.properties,
        sections: sections,
        messages: []
      };
    }),
    airstreams: airstreams
  };
}
