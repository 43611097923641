import {
  ctorsUnion,
  CtorsUnion
} from "@genesys/client-core/lib/constructors-union";
import { Cmd } from "@typescript-tea/core";
import { exhaustiveCheck } from "ts-exhaustive-check";
import * as SharedState from "../shared-state";
import { productQuery, createNewSystem, moistureLoadQuery } from "./queries";
import * as GraphQlTypes from "../graphql-types";
import * as Types from "./types";
import * as PropertiesSelector from "../properties-selector";
import * as OperatingCaseSelector from "../operating-case-selector";
import * as Guid from "@genesys/shared/lib/guid";
import { getDefaultClimateSettings } from "../climate-selector";
import { defaultProperties } from "../wizard/steps/initial-configuration";
import { promiseCmd } from "../promise-effect-manager";
import {
  operatingCasesData,
  getOpcProductData,
  initialNewProperties,
  checkboxPropertyOptions
} from "./data";
import {
  PropertyFilter,
  PropertyValue,
  PropertyValueSet
} from "@genesys/property";
import {
  getProductProperties,
  canUserCreateSystemType,
  parseNewMappings,
  FetchUserLocationDataPointsOrDefault,
  buildNewPropertiesSet,
  parseMinMaxFlows,
  parseCalculationResult,
  calculateSystem,
  parseCaseFilter
} from "./functions";
import { getAllVariants } from "./system-variant-functions";
import {
  convertMoistureLoadToOPC,
  MlOpcaseResult,
  testMoistureLoadNo,
  getMoistureLoadNoAndRevNo,
  mapQueryResultToOpc
} from "../moisture-load-calculation";
import * as Navigation from "../navigation-effect-manager";
import { ProductData } from "../operating-case-selector/types";
import {
  createNewProductProperties,
  getAllowedRanges,
  filterPropertiesForRange
} from "./data";

const allowedSystemTypesInDisplayOrder = ["MAB", "MLP", "MXN", "MXO", "MCD"];

type alertType =
  | {
      readonly type: "success" | "error";
      readonly message: string;
    }
  | undefined;

export type State = {
  readonly newPropertiesSelectorState: PropertiesSelector.State | undefined;
  readonly operatingCaseSelectorState: OperatingCaseSelector.State | undefined;
  readonly activePage: Types.Page;
  readonly systemTypes: ReadonlyArray<Types.SystemType>;
  readonly checkBoxPropertyOptions: ReadonlyArray<Types.CheckboxPropertyOption>;
  readonly newPropertiesSet: ReadonlyArray<PropertyValueSet.PropertyValueSet>;
  readonly systemVariantsGroups: ReadonlyArray<Types.SystemVariantsGroup>;
  readonly showLoader: boolean;
  readonly moistureLoadMessage: alertType;
  readonly initialMoistureLoadOPCs: OperatingCaseSelector.State | undefined;
  readonly showInvalidSystems: boolean;
  readonly isCalculatingSystems: boolean;
  readonly latestDataUsedToGenerateVariants:
    | Types.VariantsGenerationData
    | undefined;
  readonly createdSystemUrl: string | undefined;
  readonly moistureLoadInfo: Types.MoistureLoadInfo | undefined;
};

export const init = (
  sharedState: SharedState.State,
  moistureLoadNoParam?: string
): readonly [State, Cmd<Action>?, SharedState.Action?] => {
  const moistureLoadErrorMessage = moistureLoadNoParam
    ? testMoistureLoadNo(moistureLoadNoParam, sharedState)
    : "";

  return [
    {
      newPropertiesSelectorState: undefined,
      operatingCaseSelectorState: undefined,
      activePage: "limitations",
      systemTypes: [],
      checkBoxPropertyOptions: [],
      newPropertiesSet: [],
      systemVariantsGroups: [],
      initialMoistureLoadOPCs: undefined,
      moistureLoadMessage: undefined,
      showLoader: false,
      showInvalidSystems: false,
      isCalculatingSystems: false,
      latestDataUsedToGenerateVariants: undefined,
      createdSystemUrl: undefined,
      moistureLoadInfo: undefined
    },
    promiseCmd(
      async () => {
        const dataPoints = await FetchUserLocationDataPointsOrDefault(
          sharedState
        );
        const res = await sharedState.graphQL.queryUser<
          GraphQlTypes.SystemSelectionGuideProductQuery,
          GraphQlTypes.SystemSelectionGuideProductQueryVariables
        >(productQuery, {
          systemTypeInput: allowedSystemTypesInDisplayOrder.map(
            systemTypeId => ({
              systemTypeId: systemTypeId
            })
          )
        });

        let moistureLoadInfo:
          | {
              readonly moistureLoadNo: number;
              readonly revisionNo: number;
            }
          | undefined = undefined;

        if (!moistureLoadErrorMessage && moistureLoadNoParam) {
          const [moistureLoadNo, revisionNo] =
            getMoistureLoadNoAndRevNo(moistureLoadNoParam);

          moistureLoadInfo = { moistureLoadNo, revisionNo };
        }

        const moistureLoadRes = moistureLoadInfo
          ? await sharedState.graphQL.queryUser<
              GraphQlTypes.SysSelectMoistureLoadQuery,
              GraphQlTypes.SysSelectMoistureLoadQueryVariables
            >(moistureLoadQuery, {
              moistureLoadNo: moistureLoadInfo.moistureLoadNo,
              revisionNo: moistureLoadInfo.revisionNo
            })
          : undefined;

        return {
          ...res,
          dataPoints,
          moistureLoadRes,
          moistureLoadErrorMessage
        };
      },
      res => Action.dataLoaded(res)
    )
  ];
};

export const Action = ctorsUnion({
  dispatchNewPropertiesSelector: (action: PropertiesSelector.Action) => ({
    action
  }),
  dispatchOperatingCasesSelector: (action: OperatingCaseSelector.Action) => ({
    action
  }),
  dataLoaded: (data: Types.ProductQueriesData) => ({
    data
  }),
  setActivePage: (page: Types.Page) => ({ page }),
  toggleSystemType: (systemTypeId: string) => ({ systemTypeId }),
  toggleCheckboxPropertyOption: (
    optionType: Types.CheckboxPropertyOptionType
  ) => ({
    optionType
  }),
  toogleCalculateSystem: (systemIdentifier: string) => ({ systemIdentifier }),
  toogleShowInvalidSystems: () => ({}),
  createSystem: (
    createSystemInput: GraphQlTypes.CreateNewSystemsVariables
  ) => ({
    createSystemInput
  }),
  calculateSystems: (
    calculationInputs: ReadonlyArray<{
      readonly systemIdentifier: string;
      readonly createSystemInput: GraphQlTypes.SystemSelectioGuideCalculateNewSystemVariables;
    }>
  ) => ({
    calculationInputs
  }),
  systemCalculationReceived: (
    systemIdentifier: string,
    result: GraphQlTypes.SystemSelectioGuideCalculateNewSystem,
    calculateSystemInputs: ReadonlyArray<{
      readonly systemIdentifier: string;
      readonly createSystemInput: GraphQlTypes.SystemSelectioGuideCalculateNewSystemVariables;
    }>
  ) => ({ systemIdentifier, result, calculateSystemInputs }),
  generateSystemVariants: () => ({}),
  toggleExpandedVariantsGroup: (index: number) => ({ index }),
  setCreatedSystemUrl: (createdSystemUrl: string | undefined) => ({
    createdSystemUrl
  }),
  redirect: (url: string) => ({ url })
});

export type Action = CtorsUnion<typeof Action>;

export function update(
  action: Action,
  state: State,
  sharedState: SharedState.State
): readonly [
  State | undefined,
  Cmd<Action>?,
  ReadonlyArray<SharedState.Action | undefined>?
] {
  switch (action.type) {
    case "dispatchNewPropertiesSelector": {
      if (state.newPropertiesSelectorState === undefined) {
        return [state];
      }

      const [
        propertiesSelectorStates,
        propertiesSelectorCmd,
        propertiesSelectorSharedAction
      ] = PropertiesSelector.update(
        action.action,
        state.newPropertiesSelectorState,
        sharedState,
        "SkipCalculateProperties"
      );

      const newProperties = state.newPropertiesSet.map(np =>
        PropertyValueSet.merge(propertiesSelectorStates.properties, np)
      );

      return [
        {
          ...state,
          newPropertiesSelectorState: propertiesSelectorStates,
          newPropertiesSet: newProperties
        },
        Cmd.map(Action.dispatchNewPropertiesSelector, propertiesSelectorCmd),
        [propertiesSelectorSharedAction]
      ];
    }
    case "dispatchOperatingCasesSelector": {
      if (!state.operatingCaseSelectorState) {
        return [state];
      }

      const [
        operatingCasesState,
        operatingCasesCmd,
        operatingCasesSharedStateAction
      ] = OperatingCaseSelector.update(
        action.action,
        state.operatingCaseSelectorState,
        sharedState
      );

      return [
        {
          ...state,
          operatingCaseSelectorState: operatingCasesState
        },
        Cmd.map(Action.dispatchOperatingCasesSelector, operatingCasesCmd),
        [operatingCasesSharedStateAction]
      ];
    }
    case "dataLoaded": {
      const systemTypes: ReadonlyArray<Types.SystemType> =
        action.data.product.systemTypes
          .filter(st => canUserCreateSystemType(st, sharedState))
          .map(st => {
            return {
              id: st.id,
              name: st.name,
              isSelected: false,
              caseFilters: st.systemTypeCaseFilter.map(parseCaseFilter),
              opcProperties: getProductProperties(st, "OPC", sharedState),
              newProperties: getProductProperties(st, "NEW", sharedState),
              sysProperties: getProductProperties(st, "SYS", sharedState),
              newMappings: parseNewMappings(st.new.newPropertiesMapping),
              opcTemplates: st.templates.map(t => ({
                ...t,
                components: t.components.filter(c =>
                  c.productId.endsWith("OPC")
                )
              })),
              sysTemplates: st.templates.map(t => ({
                ...t,
                components: t.components.filter(c =>
                  c.productId.endsWith("SYS")
                )
              })),
              minMaxFlows: parseMinMaxFlows(st.new.flowLimitationsSI)
            };
          });

      const allowedRanges = getAllowedRanges(sharedState, systemTypes);

      const newProductProperties = createNewProductProperties(
        sharedState,
        allowedRanges
      );

      const newProductPropertiesWithFilteredRange = filterPropertiesForRange(
        newProductProperties,
        allowedRanges
      );

      const moistureLoadRes =
        action.data.moistureLoadRes?.user.moistureLoadByMoistureLoadNo;

      const newProperties = PropertyValueSet.merge(
        moistureLoadRes
          ? PropertyValueSet.setInteger("airsource", 2, initialNewProperties)
          : initialNewProperties,
        defaultProperties(newProductPropertiesWithFilteredRange)
      );

      const newPropertiesSelectorState = PropertiesSelector.init(newProperties);

      const searchedForMoistureLoad = !!action.data.moistureLoadRes;

      const moistureLoadCaseResult: MlOpcaseResult | undefined = moistureLoadRes
        ? mapQueryResultToOpc(
            {
              moistureLoadResult: moistureLoadRes.moistureLoadResult!,
              moistureloadInput: moistureLoadRes.moistureloadInput!
            },
            sharedState.translate
          )
        : undefined;

      const climateSettings = moistureLoadCaseResult
        ? PropertyValueSet.fromString(moistureLoadCaseResult.climateSettings)
        : getDefaultClimateSettings(
            sharedState,
            {},
            action.data.dataPoints.product.dataPointsForLocationId!,
            action.data.product.countries
          );

      const opcData = getOpcProductData(
        initialNewProperties,
        sharedState.translate
      );

      const operatingCasesDefault =
        OperatingCaseSelector.createInitialOperatingCases(
          climateSettings,
          operatingCasesData.map(opc => ({
            id: opc.id,
            properties: opc.settings
          })),
          opcData.properties.map((p, ix) => ({
            id: ix.toString(),
            name: p.name,
            quantity: p.quantity,
            valueSources: p.valueSources.map(v => ({
              value: v.value,
              propertyValueSourceId: v.propertyValueSourceId,
              parameters: v.parameters ?? null,
              propertyFilter: PropertyFilter.toString(v.propertyFilter),
              claimFilter: v.claimFilter
            })),
            items: p.items.map(v => ({
              id: v.id,
              value: PropertyValue.toString(v.value)
            })),
            defaultValues: []
          }))
        );

      const operatingCases = getOperatingCases(
        moistureLoadCaseResult,
        operatingCasesDefault,
        opcData
      );

      const [operatingCasesState] = OperatingCaseSelector.init(
        climateSettings,
        operatingCases.map((opc, ix) => ({
          id: ix.toString(),
          settings: opc.settings
        })),
        sharedState,
        opcData
      );

      const systemTypesInOrder: ReadonlyArray<Types.SystemType> =
        allowedSystemTypesInDisplayOrder.reduce((soFar, current) => {
          const systemType = systemTypes.find(st => st.id === current);
          return systemType ? soFar.concat([systemType]) : soFar;
        }, [] as ReadonlyArray<Types.SystemType>);

      const newPropertiesSet = buildNewPropertiesSet(
        checkboxPropertyOptions,
        newPropertiesSelectorState
      );

      return [
        {
          ...state,
          initialMoistureLoadOPCs: !!moistureLoadCaseResult
            ? operatingCasesState
            : undefined,
          newPropertiesSelectorState: newPropertiesSelectorState,
          moistureLoadMessage: getMoistureLoadMessage(
            searchedForMoistureLoad,
            moistureLoadRes,
            action.data.moistureLoadErrorMessage
          ),
          operatingCaseSelectorState: operatingCasesState,
          systemTypes: systemTypesInOrder,
          checkBoxPropertyOptions: checkboxPropertyOptions,
          newPropertiesSet: newPropertiesSet,
          moistureLoadInfo: {
            labels: moistureLoadRes?.moistureloadFile.labels,
            moistureLoadId: moistureLoadRes?.id!,
            name: moistureLoadRes?.moistureloadFile.name || ""
          }
        }
      ];
    }
    case "setActivePage": {
      return [{ ...state, activePage: action.page }];
    }
    case "toggleSystemType": {
      const systemTypes: ReadonlyArray<Types.SystemType> =
        state.systemTypes.map(st =>
          st.id === action.systemTypeId
            ? { ...st, isSelected: !st.isSelected }
            : st
        );
      return [{ ...state, systemTypes: systemTypes }];
    }
    case "toggleCheckboxPropertyOption": {
      if (state.newPropertiesSelectorState === undefined) {
        return [state];
      }

      // Toggle the option
      const checkBoxPropertyOptions = state.checkBoxPropertyOptions.map(cpo =>
        cpo.type === action.optionType
          ? { ...cpo, isSelected: !cpo.isSelected }
          : cpo
      );

      // Update newProperties
      const newProperties = buildNewPropertiesSet(
        checkBoxPropertyOptions,
        state.newPropertiesSelectorState
      );

      return [
        {
          ...state,
          checkBoxPropertyOptions: checkBoxPropertyOptions,
          newPropertiesSet: newProperties
        }
      ];
    }
    case "toogleCalculateSystem": {
      return [
        {
          ...state,
          systemVariantsGroups: state.systemVariantsGroups.map(svg => ({
            ...svg,
            systemVariants: svg.systemVariants.map(s => {
              if (s.identifier === action.systemIdentifier) {
                return { ...s, shouldCalculate: !s.shouldCalculate };
              } else {
                return s;
              }
            })
          }))
        }
      ];
    }
    case "toogleShowInvalidSystems": {
      return [{ ...state, showInvalidSystems: !state.showInvalidSystems }];
    }
    case "createSystem":
      return [
        { ...state, showLoader: true },
        sharedState.graphQL.queryUserCmd<
          GraphQlTypes.WizardCreateNewSystems,
          GraphQlTypes.WizardCreateNewSystemsVariables,
          Action
        >(createNewSystem, action.createSystemInput, res => {
          const system = res.createNewSystems[0];
          return Action.setCreatedSystemUrl(
            `/system/${sharedState.genesysPrefix.genesysNo(
              system.genesysNo,
              system.revisionNo
            )}`
          );
        }),
        [
          SharedState.Action.updateCreateSystemType([
            (
              action.createSystemInput
                .input as GraphQlTypes.CreateSystemInputType[]
            )[0].systemTypeId as any
          ]),
          SharedState.Action.updateLastCreatedSystemType(
            (
              action.createSystemInput
                .input as GraphQlTypes.CreateSystemInputType[]
            )[0].systemTypeId as any
          )
        ]
      ];
    case "calculateSystems":
      const calculationInput = action.calculationInputs[0];
      const calculationInputsQueue = action.calculationInputs.slice(1);
      return [
        { ...state, isCalculatingSystems: true },
        promiseCmd(
          async () => {
            const result = await calculateSystem(
              calculationInput.createSystemInput,
              sharedState
            );
            return result;
          },
          res =>
            Action.systemCalculationReceived(
              calculationInput.systemIdentifier,
              res,
              calculationInputsQueue
            )
        )
      ];
    case "systemCalculationReceived": {
      const systemTypeCaseFilter = state.systemTypes.find(
        st => st.id === action.result.product.systemType.id
      )!.caseFilters;

      const calculationResults = parseCalculationResult(
        action.result,
        sharedState,
        systemTypeCaseFilter
      );

      const systemVariantsGroups = state.systemVariantsGroups.reduce(
        (soFar, current) => {
          const hasCalculatedSystem =
            current.systemVariants.find(
              sv => sv.identifier === action.systemIdentifier
            ) !== undefined;

          if (!hasCalculatedSystem) {
            return soFar.concat([current]);
          } else {
            return soFar.concat([
              {
                ...current,
                systemVariants: current.systemVariants.map(sv => {
                  if (sv.identifier !== action.systemIdentifier) {
                    return sv;
                  } else {
                    return {
                      ...sv,
                      calculationResults: calculationResults,
                      shouldCalculate: false
                    };
                  }
                })
              }
            ]);
          }
        },
        [] as ReadonlyArray<Types.SystemVariantsGroup>
      );

      if (action.calculateSystemInputs.length === 0) {
        return [
          {
            ...state,
            systemVariantsGroups: systemVariantsGroups,
            isCalculatingSystems: false
          }
        ];
      } else {
        const calculationInput = action.calculateSystemInputs[0];
        const calculationInputsQueue = action.calculateSystemInputs.slice(1);

        return [
          { ...state, systemVariantsGroups: systemVariantsGroups },
          promiseCmd(
            async () => {
              const result = await calculateSystem(
                calculationInput.createSystemInput,
                sharedState
              );
              return result;
            },
            res =>
              Action.systemCalculationReceived(
                calculationInput.systemIdentifier,
                res,
                calculationInputsQueue
              )
          )
        ];
      }
    }
    case "generateSystemVariants": {
      if (!state.operatingCaseSelectorState) {
        return [state];
      }
      const systemVariants: ReadonlyArray<Types.SystemVariantsGroup> =
        getAllVariants(
          state.newPropertiesSet,
          state.systemTypes,
          state.operatingCaseSelectorState
        );
      return [
        {
          ...state,
          systemVariantsGroups: systemVariants,
          latestDataUsedToGenerateVariants: {
            newPropertiesSet: state.newPropertiesSet,
            systemTypes: state.systemTypes,
            operatingCaseSelectorState: state.operatingCaseSelectorState
          },
          showLoader: false
        }
      ];
    }
    case "toggleExpandedVariantsGroup": {
      return [
        {
          ...state,
          systemVariantsGroups: state.systemVariantsGroups.map((svg, ix) =>
            ix === action.index ? { ...svg, isExpanded: !svg.isExpanded } : svg
          )
        }
      ];
    }
    case "setCreatedSystemUrl": {
      return [
        {
          ...state,
          createdSystemUrl: action.createdSystemUrl,
          showLoader: false
        }
      ];
    }
    case "redirect":
      return [state, Navigation.pushUrl(action.url)];

    default:
      return exhaustiveCheck(action, true);
  }
}

function getMoistureLoadMessage(
  searchedForMoistureLoad: boolean,
  moistureLoadRes:
    | GraphQlTypes.WizardMoistureLoadQuery["user"]["moistureLoadByMoistureLoadNo"]
    | null
    | undefined,
  errorMessage: string
): alertType {
  if (errorMessage) {
    return {
      type: "error",
      message: errorMessage + ". Default template values being used"
    };
  } else if (searchedForMoistureLoad && !moistureLoadRes) {
    return {
      type: "error",
      message: "Moisture load not found. Using default template values"
    };
  } else if (moistureLoadRes?.id) {
    return {
      type: "success",
      message: "Opcase imported from ML"
    };
  }
  return undefined;
}

function getOperatingCases(
  moistureLoadOpcase: MlOpcaseResult | undefined,
  operatingCasesFromTemplates: ReadonlyArray<{
    readonly id: Guid.Guid;
    readonly templateComponentId: string;
    readonly settings: PropertyValueSet.PropertyValueSet;
  }>,
  opcData: ProductData
) {
  if (!moistureLoadOpcase) {
    return operatingCasesFromTemplates;
  }

  const newMlTranslatedOpcSettings = convertMoistureLoadToOPC(
    operatingCasesFromTemplates.map(opc => opc.settings),
    moistureLoadOpcase.opcResult,
    opcData.properties
  );

  return newMlTranslatedOpcSettings;
}

// tslint:disable-next-line
