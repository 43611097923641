import { PropertyFilter, PropertyValueSet } from "@genesys/property";
import * as ProductProperties from "@genesys/shared/lib/product-properties";
import { runValueSources } from "./value-sources";
import * as Guid from "@genesys/shared/lib/guid";
import { OpcProductProperty } from "./types";

export function createInitialOperatingCases(
  climateData: PropertyValueSet.PropertyValueSet,
  opcTemplateComponents: ReadonlyArray<{
    readonly id: string;
    readonly properties: PropertyValueSet.PropertyValueSet;
  }>,
  opcProductProperties: ReadonlyArray<OpcProductProperty>
): ReadonlyArray<{
  readonly id: Guid.Guid;
  readonly templateComponentId: string;
  readonly settings: PropertyValueSet.PropertyValueSet;
}> {
  const properties = [...opcTemplateComponents].sort(
    (a, b) =>
      PropertyValueSet.getInteger("sortno", a.properties)! -
      PropertyValueSet.getInteger("sortno", b.properties)!
  );

  const propertyData = opcProductProperties.map(p => ({
    name: p.name,
    quantity: p.quantity,
    defaultValues: p.defaultValues,
    values: p.items
  }));

  const defaultProperties =
    ProductProperties.createDefaultProperties(propertyData);

  const propertyDefinitions = propertyData.map(p => ({
    name: p.name,
    valueSources: opcProductProperties
      .find(pp => pp.name === p.name)!
      .valueSources.map(vs => ({
        id: "",
        value: vs.value,
        propertyValueSourceId: vs.propertyValueSourceId,
        parameters: vs.parameters !== null ? vs.parameters : null,
        propertyFilter: PropertyFilter.fromStringOrEmpty(vs.propertyFilter),
        claimFilter: vs.claimFilter
      }))
  }));

  return properties.reduce(
    (a, b) =>
      a.concat({
        id: Guid.createGuid(),
        templateComponentId: b.id,
        settings: runValueSources(
          PropertyValueSet.merge(b.properties, defaultProperties),
          a.map(aa => aa.settings),
          climateData,
          propertyDefinitions
        )
      }),
    [] as ReadonlyArray<{
      readonly id: Guid.Guid;
      readonly templateComponentId: string;
      readonly settings: PropertyValueSet.PropertyValueSet;
    }>
  );
}
