import * as React from "react";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as ClimateSettingsEditor from "../components/climate-settings-editor";
import * as RoomSettingsEditor from "../components/indoor-settings-editor";
import * as SharedState from "../../shared-state";
import * as GraphQLTypes from "../../graphql-types";
import * as PropertiesSelector from "../../properties-selector";
import { ClimateDataPresenter } from "../../climate-data-presenter";
import { Dispatch } from "@typescript-tea/core";
import { Action, State } from "../state";
import { PropertyValueSet } from "@genesys/property";
import { CtaButton } from "../components/cta-button";
import {
  ToolTipWrapper,
  BinaryToggle,
  ClearIcon,
  LogsIcon
} from "@genesys/ui-elements";
import { definitionIsValid } from "../tools";
import { IndoorClimatePresenter, ReportView } from "../components/index";
import { CalculationInput } from "../main-content/result";
import { createCalculationResultInput, getResultParams } from "./utils";
import { MoistureLoadInfo } from "./moisture-load-info";
// import { DevView } from "./dev-view";
import * as ActionMenu from "./actions-menu";
import styled from "styled-components";
import * as KnownProperties from "../tools/known-properties";
const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 5px;
  margin-bottom: 5px;
`;
const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`;

const InfoContainer = styled.div`
  align-self: flex-start;
  display: flex;
  align-items: center;
`;

const IconsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 30px;

  align-self: center;

  > span {
    margin-right: 10px;
  }
`;
const StyledDiv = styled.div`
  display: flex;
  align-items: center;

  align-self: center;

  > button {
    margin-left: 10px;
  }
`;

interface Props {
  readonly calculationInput: CalculationInput | undefined;
  readonly climateSettings: PropertyValueSet.PropertyValueSet;
  readonly isDisabled: boolean;
  readonly isDeleted: boolean;
  readonly moistureLoadItem: NonNullable<
    GraphQLTypes.MoistureLoadCalculationUserQuery["user"]["moistureLoadByMoistureLoadNo"]
  >;
  readonly sharedState: SharedState.State;
  readonly state: State;
  readonly dispatch: Dispatch<Action>;
}

export function View(props: Props) {
  const {
    climateSettings,
    moistureLoadItem,
    isDisabled,
    state,
    sharedState,
    isDeleted,
    dispatch
  } = props;

  const currentMoistureLoadSettings = PropertiesSelector.getSelectedProperties(
    state.propertiesSelectorState!
  );

  const propertyDefinitions = state.propertyDefinitions!;

  const hasValidDefs = propertyDefinitions.every(definition =>
    definitionIsValid(definition, currentMoistureLoadSettings)
  );

  const systemConfiguration = PropertyValueSet.getInteger(
    KnownProperties.systemConfiguration,
    currentMoistureLoadSettings
  )!;

  const systemOverPressure = PropertyValueSet.getInteger(
    KnownProperties.systemOverPressure,
    currentMoistureLoadSettings
  )!;

  const moistureLoadFile =
    state.userQuery?.user.moistureLoadByMoistureLoadNo?.moistureloadFile!;

  return (
    <Container>
      <InfoContainer>
        <MoistureLoadInfo
          sharedState={sharedState}
          name={moistureLoadItem.moistureloadFile.name}
          labels={moistureLoadItem.moistureloadFile.labels.map(x => x.name)}
          systems={
            moistureLoadItem.systemInformation?.map(x =>
              sharedState.genesysPrefix.genesysNo(x.genesysNo, x.revisionNo)
            ) || []
          }
          moistureLoadNo={sharedState.genesysPrefix.moistureLoadNo(
            moistureLoadItem!.moistureloadFile.moistureLoadNo,
            moistureLoadItem!.revisionNo
          )}
        />
        <ActionMenu.MenuContainer dispatch={dispatch}>
          <ActionMenu.Button dispatch={dispatch} sharedState={sharedState} />
          <ActionMenu.MenuList
            actionMenuIsOpen={state.actionMenuIsOpen}
            moistureLoadFile={moistureLoadFile}
            mno={state.moistureLoadNo}
            actionState={state.moistureloadActionState}
            dispatch={dispatch}
            sharedState={sharedState}
          />
          <ActionMenu.Content
            moistureLoadItem={moistureLoadItem}
            canChangeVisiblity={
              moistureLoadFile.owner.userName.toLowerCase() ===
              sharedState.user.userName.toLowerCase()
            }
            sharedState={sharedState}
            disabled={state.showLoader}
            userInputSearchValue={state.userInputSearch}
            message={state.aclMessage}
            dispatch={dispatch}
          />
        </ActionMenu.MenuContainer>
      </InfoContainer>

      <FlexContainer>
        <ClimateDataPresenter
          onFormatChanged={(fieldGroup, fieldName, unit, decimalCount) =>
            dispatch(
              Action.onFormatChanged(fieldGroup, fieldName, unit, decimalCount)
            )
          }
          onFormatCleared={(fieldGroup, fieldName) =>
            dispatch(Action.onFormatCleared(fieldGroup, fieldName))
          }
          title={sharedState.translate(LanguageTexts.mlcOutdoorsClimate())}
          climateSettings={climateSettings}
          sharedState={sharedState}
        />
        <ClimateSettingsEditor.Wrapper>
          <ClimateSettingsEditor.Button style={{ marginLeft: "15px" }} />

          <ClimateSettingsEditor.PopUpContent
            isDisabled={isDisabled}
            sharedState={sharedState}
            state={state.climateSettingsEditorState!}
            dispatch={Dispatch.map(
              Action.dispatchClimateSettingsEditor,
              dispatch
            )}
          />
        </ClimateSettingsEditor.Wrapper>
      </FlexContainer>

      <FlexContainer>
        <IndoorClimatePresenter
          sharedState={sharedState}
          indoorRoomSettings={currentMoistureLoadSettings}
          onFormatChanged={(fieldGroup, fieldName, unit, decimalCount) =>
            dispatch(
              Action.onFormatChanged(fieldGroup, fieldName, unit, decimalCount)
            )
          }
          onFormatCleared={(fieldGroup, fieldName) =>
            dispatch(Action.onFormatCleared(fieldGroup, fieldName))
          }
        />
        <RoomSettingsEditor.Wrapper>
          <RoomSettingsEditor.Button style={{ marginLeft: "15px" }} />
          <RoomSettingsEditor.PopUpContent
            dispatch={Dispatch.map(
              Action.dispatchIndoorSettingsEditor,
              dispatch
            )}
            currentSystemOverrides={state.currentSystemOverrides!}
            isDisabled={isDisabled}
            propertySelectorState={state.propertiesSelectorState!}
            sharedState={sharedState}
            state={state.indoorSettingsEditorState!}
            propertieSelectorDispatch={Dispatch.map(
              action => Action.dispatchPropertiesSelector(action, "climate"),
              dispatch
            )}
          />
        </RoomSettingsEditor.Wrapper>
      </FlexContainer>
      <IconsView {...props} />
      <StyledDiv>
        <CtaButton
          isDeleted={isDeleted}
          canLock={!!state.calcResult}
          systemConfiguration={systemConfiguration}
          systemOverPressure={systemOverPressure}
          hasValidDefs={hasValidDefs}
          mainState={state}
          sharedState={sharedState}
          status={moistureLoadItem!.status}
          dispatch={dispatch}
        />
      </StyledDiv>
    </Container>
  );
}

function IconsView(props: Props) {
  const {
    calculationInput,
    moistureLoadItem,
    isDisabled,
    state,
    sharedState,
    dispatch
  } = props;

  const moistureLoadCalculationResult = state.calcResult;

  const resultParams = getResultParams(
    moistureLoadCalculationResult,
    state.moistureLoadClaimValue!
  );

  const hasAccessToDevView = state.moistureLoadClaimValue === 10;

  const createIconWithToolTip = (
    tooltipTitle: string,
    content: JSX.Element
  ) => {
    return (
      <span>
        <ToolTipWrapper padding="0px" title={tooltipTitle}>
          {content}
        </ToolTipWrapper>
      </span>
    );
  };

  const logsTraceId = state.developerSettings.logsTraceId;

  return (
    <IconsContainer>
      <span>
        <ReportView
          moistureLoadItem={moistureLoadItem}
          showResult={resultParams.showReport}
          moistureLoadClaimValue={state.moistureLoadClaimValue!}
          sharedState={sharedState}
          state={state}
          dispatch={dispatch}
          calculationInput={calculationInput}
          calculationResults={createCalculationResultInput(
            moistureLoadCalculationResult?.result!
          )}
        />
      </span>

      {hasAccessToDevView &&
        createIconWithToolTip(
          "Show dev results",
          <BinaryToggle
            isOn={state.developerSettings.showDevResults}
            disabled={isDisabled || !!moistureLoadCalculationResult}
            onClick={() => {
              dispatch(
                Action.setDevSettings({
                  ...state.developerSettings,
                  showDevResults: !state.developerSettings.showDevResults
                })
              );
            }}
            // height={"25px"}
          />
        )}
      {createIconWithToolTip(
        "reset selections",
        <ClearIcon
          disabled={isDisabled}
          onClick={() => {
            dispatch(Action.resetSelections());
          }}
          height={"25px"}
        />
      )}

      {logsTraceId &&
        hasAccessToDevView &&
        createIconWithToolTip(
          "Logs ",
          <LogsIcon
            disabled={isDisabled}
            onClick={() => {
              window.open(`/log-viewer/${logsTraceId}`);
            }}
            height={"25px"}
          />
        )}
    </IconsContainer>
  );
}
