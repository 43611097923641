import * as AbstractImage from "abstract-image";
import { PropertyValue } from "@genesys/property";
import * as ProductProperties from "@genesys/shared/lib/product-properties";
import * as FlowDiagram from "@genesys/flow-diagram-gen2";
import * as Product from "../product";

export interface Props {
  readonly productData: Product.ProductData;
  readonly productId: string;
}

export function Diagram(props: Props): JSX.Element {
  const product = props.productData.products.find(
    p => p.id === props.productId
  )!;

  const diagramType = props.productData.products.find(
    p =>
      p.boxConnectionPoints.find(b => b.diagramType === "UnitConfiguration") ||
      p.boxLinePoints.find(b => b.diagramType === "UnitConfiguration") ||
      p.boxSymbols.find(b => b.diagramType === "UnitConfiguration") ||
      p.boxTexts.find(b => b.diagramType === "UnitConfiguration")
  )
    ? "UnitConfiguration"
    : "FlowDiagram";

  const diagramExpandSize = 1;

  const diagram = FlowDiagram.buildDiagram(
    false,
    diagramType,
    false,
    props.productData.products,
    {
      airstreams: [],
      components: [
        {
          id: "dummy",
          label: undefined,
          productId: product.id,
          properties: ProductProperties.createDefaultProperties(
            product.properties.map(p => {
              return {
                id: "",
                name: p.name,
                quantity: p.quantity,
                defaultValues: p.defaultValues,
                values: p.items.map(v => ({
                  value: PropertyValue.toString(v.value)
                }))
              };
            }),
            true
          ),
          sections: [],
          messages: []
        }
      ]
    },
    () => "",
    () => "",
    () => "",
    () => "",
    diagramExpandSize
  );

  const abstractImage = FlowDiagram.buildAbstractImage(
    diagram,
    [],
    [],
    [],
    props.productData.systemType.symbols,
    diagramExpandSize
  );

  return AbstractImage.createReactSvg(abstractImage);
}
